import {ReactComponent as CloseSvg} from "../../assets/svg/close.svg";
import {useMerchant} from "../../contexts/MerchantContext";
import {getCallbackUrlWithParameters, sanitizeUrl, usePayment} from "../../contexts/PaymentContext";
import {PagaStatus, usePagaStatus} from "../../contexts/PagaStatusContext";
import {logOut} from "../../services/PagaService";
import {useNavigate} from "react-router-dom";
import {PaymentStatus, usePaymentStatus} from "../../contexts/PaymentStatusContext";

const Header = () => {
    const {merchantDetails} = useMerchant()
    const {pagaStatus, showPayWithPaga} = usePagaStatus()
    const {paymentStatus, showFailedModal} = usePaymentStatus()
    const {paymentParams} = usePayment()
    const navigate = useNavigate()
    const postCancelMessage = () => {
        //confirm cancel action
        const response = window.confirm("Are you sure you want to cancel this payment?");

        if (response) {
            closeIframe()
        }
    };

    const closeIframe = ()=>{
        //Check if 3DS frame
        if (pagaStatus.status === PagaStatus.SECURE3D || paymentStatus.status === PaymentStatus.SECURE3D) {
            paymentStatus.status === PaymentStatus.SECURE3D && showFailedModal();
            pagaStatus.status === PagaStatus.SECURE3D && showPayWithPaga();

            return
        }

        //logout paga user if applicable
        pagaStatus.status !== PagaStatus.UNAUTHENTICATED && logOut();

        //Check if checkout is in an iframe
        if(window.self !== window.top){
            // Send message to parent page to indicate checkout cancellation
            window.parent.postMessage('close-iframe', '*');
        }else{
            paymentParams.charge_url &&
                window.location.replace(getCallbackUrlWithParameters(paymentParams, 'pending'));
            !paymentParams.charge_url && navigate(-1);
        }
    }

    return (
        <div className="container d-flex justify-content-between align-items-center gx-0">
            <div className="d-flex align-items-center">
                {merchantDetails.logoUrl?
                    <img className="business-logo" src={sanitizeUrl(merchantDetails.logoUrl)} alt="logo" height={30}/>:
                    null
                }
                <div className="checkout-header">{merchantDetails.name}</div>
            </div>
            <button id="close-button" className="border-0 bg-transparent p-0" onClick={postCancelMessage}>
                <CloseSvg></CloseSvg>
            </button>
        </div>
    )
}

export default Header