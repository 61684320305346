import VISA_ICON from "../assets/png/visa.png";
import VERVE_ICON from "../assets/png/verve.png";
import MASTER_CARD_ICON from "../assets/png/mastercard.png";

export const CARDNUMBERMASK = [/[0-9]/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/," ",
    /\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/];
export const VERVECARDNUMBERMASK = [/[0-9]/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/," ",
    /\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/];
export const EXPIRYDATEMASK = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CVCMASK = [/[0-9]/, /\d/, /\d/];

export const CARDICON = {
    VISA: VISA_ICON,
    MASTERCARD: MASTER_CARD_ICON,
    VERVE: VERVE_ICON
};

export const CARDARR = [
    "VISA",
    "MASTERCARD",
    "VERVE"
];
