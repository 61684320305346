import {createContext, useContext, useState} from "react";

export const OTPContext = createContext(null);

export const OTPContextProvider = ({ children }) => {
    const [otp, setOtp] = useState('');
    const [otpRequestedAt, setOtpRequestedAt] = useState(null)

    const value = {otp, setOtp, otpRequestedAt, setOtpRequestedAt}

    return(
        <OTPContext.Provider value={value}> {children} </OTPContext.Provider>
    );
}

export default OTPContext;

export const useOtp = ()=>{
    return useContext(OTPContext);
}