import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as ErrorSvg} from "../../assets/svg/error.svg";
import classNames from "classnames";

const propTypes = {
  error: PropTypes.string,
}

const defaultProps = {
  error: '',
}

const FormError = ({
    children,
    className
}) => {

    const classes = classNames(
        'error-container',
        className
    );

  return (
    <div className={classes}>
      <ErrorSvg/>
      <div className="form-error">
        {children}
      </div>
    </div>
  );
}

FormError.propTypes = propTypes;
FormError.defaultProps = defaultProps;

export default FormError;