import {createContext, useContext, useState} from "react";

export const Secure3DContext = createContext(null);

export const Secure3DContextProvider = ({ children }) => {
    const [secure3DWidget, setSecure3DWidget] = useState({
        html: '',
        url: '',
    });

    const value = { secure3DWidget, setSecure3DWidget };

    return (
        <Secure3DContext.Provider value={value}> {children} </Secure3DContext.Provider>
    );
};

export default Secure3DContext;

export const useSecure3D = ()=>{
    return useContext(Secure3DContext);
}

export const remove3DSFormPostMethod = (html) => {
    // Find the form element.
    const formElement = html.match(/<form([^>]+)>/)

    if(!formElement){
        return html
    }
    const form = formElement[1]

    // If there is no `method="post"` attribute, return the original HTML.
    if (!form.includes(`method="post"`)) {
        return html
    }

    // Remove the `method="post"` attribute from the form element.
    html = html.replace(
        form,
        form.replace(`method="post"`, "")
    )

    return html
}