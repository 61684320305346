import React from 'react';
import PropTypes from 'prop-types';
import {ListGroup} from "react-bootstrap";
import {useMenuSelection} from "../../contexts/MenuSelectionContext";
import classNames from "classnames";
import {logOut} from "../../services/PagaService";
import {PagaStatus, usePagaStatus} from "../../contexts/PagaStatusContext";

const propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  logo: PropTypes.any,
  show: PropTypes.bool,
  formatText: PropTypes.bool,
  verticalPadding: PropTypes.string
}

const defaultProps = {
  text: '',
  link: '/',
  logo: <></>,
  show: true,
  formatText: true,
  verticalPadding: '11px'
}

const PaymentItem = ({
  text,
  link,
  logo,
  show,
  verticalPadding,
  formatText,
  ...props
}) => {

  const { selected, changeSelected, setMobileMenuOpen } = useMenuSelection();
  const {showLoggedOut, pagaStatus} = usePagaStatus()

  const isSelected = link === selected;
  const textClassName = isSelected? "list-text selected": "list-text";

  //format label text to be first letter of each word uppercase and rest is lowercase
  if(formatText){
    text = text
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  }

  const handleOnSelected = ()=>{
    //close mobile menu
    setMobileMenuOpen(false)

    changeSelected(link)

    //log out authenticated user
    if(selected === 'paga'){
      if([
        PagaStatus.PIN,
        PagaStatus.OTP,
        PagaStatus.SECURE3D,
        PagaStatus.AUTHENTICATED
      ].includes(pagaStatus.status)){
        showLoggedOut()
        logOut().catch(console.error);
      }
    }
  }

  const itemClassName = classNames(
      isSelected && 'selected',
      'list-item',
  )

  const itemStyle = {
      paddingTop : verticalPadding,
      paddingBottom : verticalPadding
  }

  return (
      <>
        {
          show?
              <div onClick={handleOnSelected}>
                <ListGroup.Item className={itemClassName} style={itemStyle}>
                  <div className="d-flex">
                    {logo}
                    <p className={textClassName}>
                      {text}
                    </p>
                  </div>
                </ListGroup.Item>
              </div>:
              null
        }
      </>
  );
}

PaymentItem.propTypes = propTypes;
PaymentItem.defaultProps = defaultProps;

export default PaymentItem;