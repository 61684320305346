import ButtonWidget from "../components/elements/ButtonWidget"
import {useState} from "react"
import PageHeading from "../components/elements/PageHeading"
import {usePayment} from "../contexts/PaymentContext"
import {Form} from "react-bootstrap"
import Select from "react-select"
import {Toaster} from "react-hot-toast"
import classNames from "classnames"
import {ReactComponent as ArrowDownSvg} from "../assets/svg/arrow-down.svg"
import {useMerchant} from "../contexts/MerchantContext"
import {useUssdStatus} from "../contexts/UssdContext"
import {requestPayment} from "../services/PaymentService"
import {FundingSourceType, ValueType} from "../constants/PaymentRequestConstants"
import {ErrorClass} from "../constants/ErrorConstants";
import PropTypes from "prop-types";

const propTypes = {
    channel: PropTypes.string,
    banks: PropTypes.array,
    onBankSelected: PropTypes.func
}

const defaultProps = {
    channel: FundingSourceType.USSD,
    banks: [],
    onBankSelected: ()=>{}
}

const SelectBank = ({channel, banks, onBankSelected})=>{
    const {getFeeDetailsByChannel} = useMerchant()
    const [requestLoading, setRequestLoading] = useState(false)
    const channelName =
        FundingSourceType.getMappedValue(channel, ValueType.DEFAULT, ValueType.CHANNEL)
    const feeDetails = getFeeDetailsByChannel()
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
    const [selectedBank, setSelectedBank] = useState(null)
    const showSearchSelect = !dropdownMenuOpen && (selectedBank === null)
    const formInputClasses = classNames(
        showSearchSelect? 'search-select': 'search-no-select',
    )

    const onButtonClicked = () => {
        setRequestLoading(true)

        onBankSelected(selectedBank.value).finally(()=>{
            setRequestLoading(false)
        })
    }

    function createBanksArray() {
        const result = []

        if(banks === null){return []}

        banks.forEach((obj) => {
            const newObj = {}
            newObj.value = obj['id']
            newObj.label = obj['name']

            if (obj['name'] === 'Paga') {
                result.unshift(newObj)
            } else {
                result.push(newObj)
            }
        })

        return result
    }
    const banksArray = createBanksArray()
    const dropdownStyles  = {
        control: (styles) => (
            { ...styles,
                textAlign: 'start',
                border: '0px',
                fontSize: '14px'
            }),
        option: (styles) =>  ({ ...styles, textAlign: 'start'}),
        container: (styles) =>  ({ ...styles, padding: showSearchSelect? '0px 6px': 0, border: 0}),
        menuList: (styles) =>  (
            { ...styles,
                height: window.innerWidth <= 720? '500px': '200px',
                fontSize: '14px'
            })
    }

    const DropdownIndicator = () => {
        return (
            dropdownMenuOpen? <></>:
                <ArrowDownSvg className={'mx-2'}/>
        )
    }

    return (
        <div className="box">
            <div>
                <PageHeading title={"Pay with " + channelName}
                             description="Choose your bank to begin payment"/>

                <div className="input-container mt-3">
                    <div className={formInputClasses}>
                        <Form className="text-start">
                            {showSearchSelect?
                                <div className="text-sm input-label grey-color" style={{paddingLeft: '16px'}}>
                                    Choose a Bank
                                </div>: null
                            }

                            <Select
                                id='search-select'
                                options={banksArray}
                                placeholder='Select Bank'
                                styles={dropdownStyles}
                                components={{ DropdownIndicator }}
                                onMenuOpen={()=>setDropdownMenuOpen(true)}
                                onMenuClose={()=>setDropdownMenuOpen(false)}
                                noOptionsMessage={()=>{return 'No Bank'}}
                                value={selectedBank}
                                onChange={(newValue) => {
                                    setSelectedBank(newValue)
                                }}
                            />

                        </Form>
                    </div>
                </div>
            </div>

            <div>
                <ButtonWidget className="modal-btn" disabled={!selectedBank}
                              loading={requestLoading} onClick={onButtonClicked}>
                    {'Pay ₦' + feeDetails.total}
                </ButtonWidget>
                <p className="page-text text-sm mt-1">
                    {`Includes fee ₦${feeDetails.amount}`}
                </p>
            </div>

            <Toaster/>
        </div>
    )
}

SelectBank.propTypes = propTypes;
SelectBank.defaultProps = defaultProps;

export default SelectBank
