import PropTypes from 'prop-types'
import classNames from "classnames"
import {useMenuSelection} from "../../contexts/MenuSelectionContext"
import {ReactComponent as MenuSvg} from "../../assets/svg/menu.svg"
import {ReactComponent as CancelSvg} from "../../assets/svg/cancel.svg"
import {ReactComponent as BackSvg} from "../../assets/svg/back.svg"

const propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    textStartDescription: PropTypes.bool,
    showChangeLink: PropTypes.bool,
    showBackLink: PropTypes.bool,
    hasBackButton: PropTypes.bool,
    changeButtonPadding: PropTypes.bool,
    onBackButtonClicked: PropTypes.func
}

const defaultProps = {
    title: '',
    description: '',
    textStartDescription: true,
    showChangeLink: true,
    showBackLink: false,
    hasBackButton: false,
    changeButtonPadding: false,
    onBackButtonClicked: ()=>{}
}

const PageHeading = ({title, description, showChangeLink, textStartDescription, onBackButtonClicked,
                         showBackLink, hasBackButton, children, className, changeButtonPadding}) => {
    const classes = classNames(
        className
    )
    let changeLink = null
    let backLink = null
    let backButton = null
    const {changeToPreviousSelected, mobileMenuOpen, setMobileMenuOpen} = useMenuSelection()

    const handleGoToPreviousPayment = ()=>{
        changeToPreviousSelected()
    }

    const handleMenuButtonClick = () => {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    if(showChangeLink){
        changeLink =
            <button onClick={handleMenuButtonClick}
                    className="bg-transparent border-0 only-display-mobile"
                    style={changeButtonPadding?
                        {paddingRight: '20px', paddingTop: '20px'}: {}}>
                {
                    mobileMenuOpen?
                        <CancelSvg/> : <MenuSvg/>
                }
            </button>
    }

    if(showBackLink){
        backLink =
            <button onClick={handleGoToPreviousPayment}
                    className="link-text only-display-mobile bg-transparent border-0">
                Back
            </button>
    }

    if(hasBackButton){
        backButton =
            <BackSvg className="clickable" onClick={()=>onBackButtonClicked()}/>
    }

    return (
        <div className={classes}>
            <div className="page-heading d-flex justify-content-between align-items-center">
                <>
                    {backButton}
                    { title &&
                        <div className="page-heading-text">{title}</div>}
                    {children}
                </>

                <>
                    {changeLink}
                    {backLink}
                </>
            </div>

            <div className="page-text text-sm" style={{textAlign: textStartDescription? 'start': 'initial'}}>
                {description}</div>
        </div>
    )
}

PageHeading.propTypes = propTypes
PageHeading.defaultProps = defaultProps

export default PageHeading