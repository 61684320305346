import {createContext, useContext, useState, useReducer} from "react"

export const CardContext = createContext(null)

const reducer = (card, action) => {
    switch (action.type) {
        case "cardNumber":
            return { ...card, cardNumber: action.data }
        case "expiryDate":
            return { ...card, expiryDate: action.data }
        case "cvc":
            return { ...card, cvc: action.data }
        case "cleanState":
            return { ...action.data }
        default:
            return card
    }
}

export const CardContextProvider = ({ children }) => {
    const [cardType, setCardType] = useState('')
    const [card, setCard] = useReducer(reducer, {
        cardNumber: "",
        expiryDate: "",
        cvc: "",
    })
    const [error, setError] = useState({})
    const hasError = ()=>{
        for (const key in error) {
            if(error[key]){
                if (error[key].length !== 0) {
                    return true
                }
            }
        }
        return false
    }

    const hasEmptyCardDetail = ()=>{
        for (const key in card) {
            if (card[key] === null || card[key].length === 0 || card[key] === undefined) {
                return true
            }
        }
        return false
    }

    const hasIncompleteCardDetail = ()=>{
        if(card['expiryDate'].length !== 5){
            return true
        }

        if(card['cvc'].length !== 3){
            return true
        }

        return false
    }

    const emptyCardDetails = () => {
        setCard({
            type: 'cleanState',
            data: {
                cardNumber: "",
                expiryDate: "",
                cvc: "",
            }
        })
        setCardType('')
        setError({})
    }

    const value = {card, setCard, cardType, setCardType,
        error, setError, hasError, hasEmptyCardDetail, hasIncompleteCardDetail, emptyCardDetails}

    return(
        <CardContext.Provider value={value}> {children} </CardContext.Provider>
    )
}

export default CardContext

export const useCard = ()=>{
    return useContext(CardContext)
}