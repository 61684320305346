import ButtonWidget from "../../../components/elements/ButtonWidget"
import PaymentFailedGif from "../../../assets/gif/paymentfailed.gif";
import PageHeading from "../../../components/elements/PageHeading";
import {useTransferStatus} from "../../../contexts/TransferStatusContext";
import {ErrorClass} from "../../../constants/ErrorConstants";

const FailedTransfer = ()=>{
    const {loadTransferDetails, transferStatus} = useTransferStatus()

    return (
        <div className="box">
            <div>
                <PageHeading title="Pay with transfer"
                             description="" className="mb-2"/>

                <img src={PaymentFailedGif} width={124} height={124} alt="Payment Failed"/>

                <div className="grey-container align-items-center">
                    {
                        transferStatus.message === ErrorClass.FAILED_INITIALIZATION &&
                        <div className="text-xl fw-bold">
                            {ErrorClass.FAILED_INITIALIZATION_HEADING}
                        </div>
                    }

                    <div className="text-md grey-color">
                        {transferStatus.message ? transferStatus.message :
                            ErrorClass.FAILED_TRANSFER_PAYMENT
                        }
                    </div>
                </div>
            </div>

            <div>
                <ButtonWidget className="modal-btn" onClick={()=>{
                    loadTransferDetails()
                }}>
                    Retry
                </ButtonWidget>
            </div>
        </div>
    )
}

export default FailedTransfer
