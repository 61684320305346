import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Checkout from "./components/layout/Checkout"
import {PaymentStatusContextProvider} from "./contexts/PaymentStatusContext"
import {Secure3DContextProvider} from "./contexts/Secure3DContext"
import {MenuSelectionContextProvider} from "./contexts/MenuSelectionContext"
import {Routes, Route} from "react-router-dom"
import {PaymentContextProvider} from "./contexts/PaymentContext"
import {MerchantContextProvider} from "./contexts/MerchantContext"
import Receipt from "./pages/Receipt"
import {PagaStatusContextProvider} from "./contexts/PagaStatusContext"
import {CardContextProvider} from "./contexts/CardContext";

function App() {
    const checkoutApp = () =>
        <PaymentContextProvider>
            <MerchantContextProvider>
                <Secure3DContextProvider>
                    <PagaStatusContextProvider>
                        <PaymentStatusContextProvider>
                            <MenuSelectionContextProvider>
                                <CardContextProvider>
                                    <Checkout/>
                                </CardContextProvider>
                            </MenuSelectionContextProvider>
                        </PaymentStatusContextProvider>
                    </PagaStatusContextProvider>
                </Secure3DContextProvider>
            </MerchantContextProvider>
        </PaymentContextProvider>

      return (
          <Routes>
              <Route path="/" element={checkoutApp()}/>
              <Route path="/payment-complete" element={<Receipt/>}/>
          </Routes>
      )
}

export default App
