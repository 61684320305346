import {createContext, useContext, useState} from "react";
import {usePayment} from "./PaymentContext";

export const MerchantContext = createContext(null);

export const MerchantContextProvider = ({ children }) => {
    const [merchantDetails, setMerchantDetails] = useState({
        accountId: "",
        accountNumber: "",
        name: "",
        email: "",
        phoneNumber: "",
        logoUrl: "",
        displayName: ""
    });
    const [feeDetails, setFeeDetails] = useState([])
    const {paymentParams} = usePayment()

    const getFeeDetailsByChannel = (channel, cardType = null) => {
        const fee = feeDetails.find((item) => {
            if (cardType !== null){
                return item.channel === channel && item.cardType === cardType
            }
            return item.channel === channel
        });

        if (fee) {
            return {
                amount: fee.amount? formatToReadableAmount(fee.amount) : 0.00,
                currency: fee.currency,
                taxes: {
                    vat: formatToReadableAmount(fee.taxes?.vat || 0)
                },
                total:
                    formatToReadableAmount(
                        (parseFloat(fee.amount) || 0) +
                        parseFloat(paymentParams?.amount || 0)
                    ),
                totalFloat:
                        (parseFloat(fee.amount) || 0) +
                        parseFloat(paymentParams?.amount || 0)
            };
        } else {
            return {
                amount: 0.00,
                currency: 'NGN',
                taxes: {
                    vat: 0.00
                },
                total: formatToReadableAmount(parseFloat(paymentParams?.amount || 0).toFixed(2)),
                totalFloat: parseFloat(paymentParams?.amount || 0).toFixed(2)
            };
        }
    };

    const value = {merchantDetails, setMerchantDetails, getFeeDetailsByChannel, setFeeDetails}

    return(
        <MerchantContext.Provider value={value}> {children} </MerchantContext.Provider>
    );
}

export default MerchantContext;

export const useMerchant = ()=>{
    return useContext(MerchantContext);
}

function formatToReadableAmount(amount) {
    try {
        // Convert the input to a floating-point number and round it to 2 decimal places
        const formattedAmount = parseFloat(amount).toFixed(2);

        // Use regular expressions to add commas as thousands separators
        const parts = formattedAmount.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return parts.join('.');
    } catch (error) {
        return amount;
    }
}