import axios from 'axios';
import {getBaseUrl, getOtpRequestPayload, getRequestPayload} from "./BaseService";
import {FundingSourceType} from "../constants/PaymentRequestConstants";

axios.defaults.withCredentials = true

export async function chargeCard(paymentParams, cardDetails, chargeUrl = null, pin = null){
    let url

    if(chargeUrl){
        url = chargeUrl
    }else {
        url = getBaseUrl() + "/card/charge/v1.0"
    }

    const data = {
        "cardNumber": cardDetails.cardNumber.replace(/\s/g, ''),
        "expiry": convertExpiryDate(cardDetails.expiryDate),
        "securityCode": cardDetails.cvc,
        "pin": pin
    }

    const body = getRequestPayload(paymentParams, FundingSourceType.DEBIT_CARD, data)

    const response = await axios.post(url, body);
    return response.data
}

function convertExpiryDate(expiryDate) {
    const [month, year] = expiryDate.split("/");
    if (!month || !year || year < 0 || year > 99 || month < 1 || month > 12) {
        throw new Error("Invalid expiry date format");
    }
    const yearFull = `20${year}`;
    return `${yearFull}-${month}-01T00:00:00`;
}

export async function verifyOTP(paymentParams, otp){
    const body = getOtpRequestPayload(paymentParams, otp);

    const response = await axios.post(getBaseUrl() + "/card/authorize/v1.0", body);
    return response.data
}


export async function resendOTP(paymentParams){
    const body = {
        "processReferenceNumber": paymentParams.payment_reference,
        "merchantAccountId": paymentParams.public_key
    }

    const response = await axios.post(getBaseUrl() + "/card/reset/v1.0", body);
    return response.data
}

export async function getVgsProxyUrl(cardDetails){
    const data = {
        "cardNumber": cardDetails.cardNumber.replace(/\s/g, ''),
    }

    const response = await axios.post(getBaseUrl() + "/funding/card-details/v1.0", data);
    return response.data
}

export async function authorise3D(){
    return await axios.post(getBaseUrl() + "/card/authorise-3d-secure/v1.0", {
        "transactionRef": "P7WER4K2SD",
        "amount": "2000"
    }, {
        headers: {
            'content-type': 'application/json'
        }
    });
}