import {validateEmail, validatePhoneNumber} from "../validators/DetailsValidator";

const currentUrl = new URL(window.location.href).host
let baseUrl

export const getApiUrl = (suffixed = true) => {
    const urlSuffix = '/checkout';
    const urlOrigin = window.env.PAGA_CHECKOUT_API_BASE_URL?
        window.env.PAGA_CHECKOUT_API_BASE_URL: 'https://' + currentUrl

    return !suffixed? urlOrigin: urlOrigin + urlSuffix;
};

baseUrl = getApiUrl()

export const getBaseUrl = ()=>{
    return baseUrl;
}

export const getRequestPayload = (paymentParams, fundingSourceType, payload = {}, customerIdentifier = null) => {
    return {
        referenceNumber: paymentParams.payment_reference,
        merchantAccountId: paymentParams.public_key,
        amount: paymentParams.amount,
        currency: paymentParams.currency,
        merchantCustomerAccountNumber: paymentParams.account_number ? paymentParams.account_number : null,
        customerEmail: validateEmail(paymentParams.email) ? paymentParams.email : null,
        customerPhoneNumber: validatePhoneNumber(paymentParams.phone_number) ? paymentParams.phone_number : null,
        customerIdentifier: customerIdentifier,
        customerFirstName: null,
        customerLastName: null,
        serviceDescription: paymentParams.product_description,
        actorNature: null,
        mediumNature: null,
        note: null,
        isAllowPartialPayment: false,
        isAllowOverPayment: false,
        callbackUrl: paymentParams.callback_url,
        ...payload
    };
}

export function getOtpRequestPayload(paymentParams, otp) {
    return {
        "processReferenceNumber": paymentParams.payment_reference,
        "merchantAccountId": paymentParams.public_key,
        "callbackUrl": paymentParams.callback_url,
        "authorizationData": {
            "otp": otp
        }
    };
}

export function generateId(uuidLength = 4) {
    const currentDate = new Date();
    const dateStamp = currentDate.toISOString().slice(0, 10).replace(/-/g, ''); // Get current date in YYYYMMDD format
    const timeStamp = currentDate.toTimeString().slice(0, 8).replace(/:/g, ''); // Get current time in HHMMSS format
    const randomUUID = generateRandomUUID(uuidLength); // Generate random UUID

    return `Paga_Auto_Ref_${dateStamp}_${timeStamp}_${randomUUID}`;
}

function generateRandomUUID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // available characters for UUID string
    const charactersLength = characters.length;

    // loop through the available characters and select random characters to build the UUID string
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}