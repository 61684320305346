import ButtonWidget from "../../components/elements/ButtonWidget"
import PageHeading from "../../components/elements/PageHeading"
import {ReactComponent as MainLogo} from "../../assets/svg/logo/main.svg"
import {usePagaStatus} from "../../contexts/PagaStatusContext"
import FormError from "../../components/elements/FormError"
import {ReactComponent as SmallLogoSvg} from "../../assets/svg/logo/small1.svg"
import {ReactComponent as BulletSvg} from "../../assets/svg/bullet.svg"
import {ReactComponent as MasterCardSvg} from "../../assets/svg/mastercard-no-border.svg"
import {ReactComponent as VerveSvg} from "../../assets/svg/verve-no-border.svg"
import {ReactComponent as VisaSvg} from "../../assets/svg/visa-no-border.svg"
import {ReactComponent as BankSvg} from "../../assets/svg/bank-dynamic.svg"
import EyeToggle from "../../components/elements/EyeToggle"
import {useEffect, useState} from "react"
import PagaOption from "../../components/elements/PagaOption"
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import {chargeLinkedBankAccount, chargeLinkedCard, chargePagaAccount, logOut} from "../../services/PagaService"
import {usePayment} from "../../contexts/PaymentContext"
import {useMerchant} from "../../contexts/MerchantContext"
import {ErrorClass} from "../../constants/ErrorConstants"
import {useSecure3D} from "../../contexts/Secure3DContext";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";

const PayWithPaga = ()=>{
    const [paymentLoading, setPaymentLoading] = useState(false)
    const {paymentParams} = usePayment()
    const {loginInfo, showLoggedOut, pagaStatus, setErrorMessage, setSelectedFundingSource, showPINAuth,
        showSuccessfulPayment, fundingSources, showOTPAuth, showSecure3DAuth} = usePagaStatus()
    const [showBalance, setShowBalance] = useState(false)
    const [radioValue, setRadioValue] = useState(0)
    const {getFeeDetailsByChannel} = useMerchant()
    const [pagaFeeDetails, setPagaFeeDetails] = useState(getFeeDetailsByChannel('PAGA'))
    const {setSecure3DWidget} = useSecure3D()

    useEffect(()=>{
        setFeesByFundingSourceType(fundingSources[radioValue])
    }, [fundingSources, radioValue])

    const onToggleShowBalance = ()=>{
        setShowBalance(!showBalance)
    }

    const logOutUser = async ()=>{
        try{
            const res = await logOut()

            if(res){
                showLoggedOut()
            }

        }catch (e) {

        }
    }

    function addCommasToNumber(number) {
        const numberStr = number.toString();
        return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const optionHeightStyle = window.innerWidth >= 721? {
        height: !pagaStatus.message? 250 : 230
    }: {
        height: !pagaStatus.message? '66vh' : '65vh'
    }

    const setFeesByFundingSourceType = (fundingSource)=>{
        let feeDetails
        switch (fundingSource.type){
        case 'PAGA':
            feeDetails = getFeeDetailsByChannel('PAGA')
            break
        case 'BANK_ACCOUNT':
            feeDetails =
                getFeeDetailsByChannel('BANK')
            break
        case 'CARD':
            feeDetails = getFeeDetailsByChannel('CARD', fundingSource.cardType)
            break
        default:
            feeDetails = getFeeDetailsByChannel('PAGA')
            break
        }

        setPagaFeeDetails(feeDetails)
    }

    const chargeFundingSource = async () => {
        const paymentSource = fundingSources[radioValue]

        setPaymentLoading(true)
        try {
            let chargeFunction
            switch (paymentSource.type){
                case 'PAGA':
                    setSelectedFundingSource(FundingSourceType.PAGA)
                    chargeFunction = chargePagaAccount(paymentParams, paymentSource.id)
                    break
                case 'BANK_ACCOUNT':
                    setSelectedFundingSource(FundingSourceType.PAGA_BANK)
                    chargeFunction =
                        chargeLinkedBankAccount(paymentParams, paymentSource.id, paymentSource.bankId)
                    break
                case 'CARD':
                    setSelectedFundingSource(FundingSourceType.PAGA_CARD)
                    chargeFunction =
                        chargeLinkedCard(paymentParams, paymentSource.id)
                    break
                default:
                    chargeFunction = null
                    break
            }

            if(chargeFunction == null) return

            const res = await chargeFunction

            // If successful
            if ((res.status === "SUCCESS") || (res.status === "SUCCESSFUL")) {
                //set success modal
                showSuccessfulPayment()

                // If authorization needed
            } else if (res.status === "PENDING_AUTHORIZATION") {
                switch (res.responseData.authorizationType) {
                    case "otp":
                        showOTPAuth(res.statusMessage)
                        break
                    case "3ds":
                        setSecure3DWidget({
                            html: res.responseData.authorizationHtml,
                            url: res.responseData.authorizationUrl
                        })
                        showSecure3DAuth()
                        break
                    case "pin":
                        showPINAuth()
                        break
                    default:
                        break
                }
            }else if (res.status === "FAILED"){
                setErrorMessage(res.statusMessage)
            }else {
                setErrorMessage(res.statusMessage)
            }
        } catch (err){
            setErrorMessage(ErrorClass.FAILED_INITIALIZATION)
        } finally {
            setPaymentLoading(false)
        }
    }

    return (
        <div className="box">
            <div>
                <PageHeading className="" title="" description="">
                    <MainLogo className="mb-2"/>
                </PageHeading>

                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <div className="modal-text text-font-md">{loginInfo}</div>
                    <button onClick={logOutUser} className="link-text bg-transparent border-0 text-font-md">
                        Log out
                    </button>
                </div>

                { pagaStatus.message ?
                    <FormError className="mt-2">{pagaStatus.message}</FormError>: null }

                <SimpleBar id="paga-options" style={optionHeightStyle} className="scrollable">
                    {   fundingSources !== null &&
                        fundingSources.map((paymentOption, index) => {
                                let optionWidget

                                switch (paymentOption.type) {
                                    case 'PAGA':
                                        optionWidget =
                                            <PagaOption
                                                key={index}
                                                keyValue={index}
                                                radioValue={radioValue}
                                                onClick={() => {
                                                    setRadioValue(index)
                                                }}
                                                disabled={paymentOption.accountBalance < pagaFeeDetails.totalFloat}
                                                leading={<SmallLogoSvg/>}
                                                trailing=
                                                    {<EyeToggle className="mx-1" onToggle={onToggleShowBalance}
                                                                showValue={showBalance}/>}
                                            >
                                                <div className="mx-2 modal-text text-lg">Balance:</div>
                                                {showBalance ?
                                                    <div className="modal-text text-lg">
                                                        ₦{addCommasToNumber(paymentOption.accountBalance)}
                                                    </div> :
                                                    <>
                                                        {[...Array(9)].map((item, index) =>
                                                            <BulletSvg className="bullet" key={index}/>)}
                                                    </>
                                                }
                                            </PagaOption>
                                        break
                                    case 'BANK_ACCOUNT':
                                        optionWidget =
                                            <PagaOption key={index} keyValue={index} radioValue={radioValue}
                                                        onClick={() => {
                                                            setRadioValue(index)
                                                        }} leading={<BankSvg/>}>
                                                <div className="mx-2 modal-text text-lg">
                                                    {paymentOption.bankName? paymentOption.bankName: ''}
                                                </div>
                                                <div className="modal-text text-lg">{paymentOption.accountNumber}</div>
                                            </PagaOption>
                                        break
                                    case 'CARD':
                                        let leadingSvg
                                        const cardType = paymentOption.cardType
                                        switch (cardType) {
                                            case 'MASTERCARD':
                                                leadingSvg = <MasterCardSvg/>
                                                break
                                            case 'VERVE':
                                                leadingSvg = <VerveSvg/>
                                                break
                                            case 'VISA':
                                                leadingSvg = <VisaSvg/>
                                                break
                                            default:
                                                leadingSvg = <></>
                                                break
                                        }
                                        optionWidget =
                                            <PagaOption key={index} keyValue={index} radioValue={radioValue}
                                                        onClick={() => {
                                                            setRadioValue(index)
                                                        }} leading={leadingSvg}>
                                                <div className="mx-2 modal-text text-lg">
                                                    {cardType.charAt(0).toUpperCase() + cardType.slice(1).toLowerCase()}
                                                </div>
                                                <div className="modal-text text-lg">
                                                    {'**** ' + paymentOption.cardNumber.slice(-4)}
                                                </div>
                                            </PagaOption>
                                        break
                                    default:
                                        break

                                }

                                return optionWidget
                            })
                    }
                </SimpleBar>
            </div>

            <div className="d-flex flex-column justify-content-between sticky-bottom">
                <ButtonWidget className="margin-mobile" type="submit" onClick={chargeFundingSource}
                              loading={paymentLoading}
                              disabled={!fundingSources ||
                                  ((fundingSources[0].accountBalance < pagaFeeDetails.totalFloat)
                                      && fundingSources.length < 2)}>
                     {'Pay ₦' + pagaFeeDetails.total}
                </ButtonWidget>
                <p className="page-text text-sm mt-1">
                    {'Includes fee ₦' + pagaFeeDetails.amount}
                </p>
            </div>

        </div>
    )
}

export default PayWithPaga
