import { Modal } from 'react-bootstrap';
import SuccessGif from '../assets/gif/success.gif';
import ButtonWidget from "../components/elements/ButtonWidget";
import CountdownTimer from "../components/elements/CountdownTimer";
import {getCallbackUrlWithParameters, usePayment} from "../contexts/PaymentContext";
import {useMerchant} from "../contexts/MerchantContext";

function SuccessModal({show, onHide, paymentDetails}) {
    const {paymentParams} = usePayment()
    const {merchantDetails} = useMerchant()
    const actionButtonText = paymentParams.charge_url? 'Return to merchant site': 'View Receipt'

    const handleOnReturnHome = ()=>{
        let url
        if(paymentParams.charge_url){
            url = getCallbackUrlWithParameters(paymentParams);
        }else{
            // Store the data in sessionStorage
            const receiptDataString = JSON.stringify({ paymentDetails, merchantDetails });

            url = `/payment-complete?data=${encodeURIComponent(receiptDataString)}`
        }

        // redirect to url
        window.parent.location.replace(url);
    }

    const handleTimerCompletion = ()=>{
        handleOnReturnHome()
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body className="m-3">
                <img src={SuccessGif} className="gif" alt="success"/>
                <h5 className="modal-heading-text">Payment Success</h5>
                <h6 className="modal-text mt-3">
                    Your payment has been successfully received
                </h6>
                <h6 className="modal-text text-sm mt-4">
                    You're being redirected, it will take up to <CountdownTimer onCompletion={handleTimerCompletion}
                                                                                seconds={5} type="seconds"/>
                </h6>
                <ButtonWidget className="modal-btn mt-3" onClick={handleOnReturnHome}>
                    {actionButtonText}
                </ButtonWidget>
            </Modal.Body>
        </Modal>
    );
}

export default SuccessModal;
