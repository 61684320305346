import ButtonWidget from "../../components/elements/ButtonWidget"
import {ReactComponent as CopySvg} from '../../assets/svg/copy.svg'
import FormError from "../../components/elements/FormError"
import PageHeading from "../../components/elements/PageHeading"
import CountdownTimer from "../../components/elements/CountdownTimer"
import copy from 'copy-to-clipboard'
import toast, { Toaster } from 'react-hot-toast'
import {usePayment} from "../../contexts/PaymentContext"
import SuccessModal from "../SuccessModal"
import {TransferStatus, useTransferStatus} from "../../contexts/TransferStatusContext"
import FailedTransfer from "./failed/FailedTransfer"
import {checkPaymentStatus, handlePaymentStatus, requestPayment} from "../../services/PaymentService";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";
import {useState, useEffect} from "react";
import Loader from "../../components/elements/Loader";
import {ErrorClass} from "../../constants/ErrorConstants";
import {useMerchant} from "../../contexts/MerchantContext";

const TransferPayment = ()=>{
    const defaultTimerCountInSeconds = 60 * 30
    const pollingInterval = window.env.POLLING_DURATION_SECS * 1000
    const [buttonLoading, setButtonLoading] = useState(false)
    const {paymentParams} = usePayment()
    const {merchantDetails, getFeeDetailsByChannel} = useMerchant()
    const [isLoading, setIsLoading] = useState(false)
    const {transferStatus, showFailed, showSuccess, transferDetails,
        setTransferDetails, showTransferDetails} = useTransferStatus()
    const [paymentError, setPaymentError] = useState(null)
    const transferFeeDetails = getFeeDetailsByChannel('TRANSFER')
    let copiedToast
    let timer

    useEffect(()=>{
        if(!transferDetails.accountNumber && transferStatus.status === TransferStatus.UNINITIALIZED){
            requestTransferPayment()
        } else if (transferStatus.status === TransferStatus.INITIALIZED) {
            // Start the timer only when transfer is initialized
            timer = setTimeout(startPolling, pollingInterval);
        }

        return ()=>{
            clearTimeout(timer)
            if(copiedToast){
                toast.dismiss(copiedToast)
            }
        }
    }, [transferStatus, transferDetails])

    const startPolling = () => {
        checkTransferPaymentStatus(false).then(()=>{
            // Schedule the next poll after the interval duration
            timer = setTimeout(startPolling, pollingInterval);
        });
    };

    const copyText = (text) => {
        copy(text)
        if(copiedToast){
            toast.dismiss(copiedToast)
        }
        copiedToast = toast('Copied to clipboard', {
            position: 'bottom-right',
            duration: 2000,
            className: 'page-text',
        })
    }

    const checkTransferPaymentStatus = async (showError = true)=>{
        if(showError) setButtonLoading(true)

        try {
            checkPaymentStatus(paymentParams, FundingSourceType.TRANSFER)
                .then(response => response.json())
                .then((res) =>{
                    handlePaymentStatus(res, setPaymentError, showSuccess, showError)
                    if(showError) setButtonLoading(false)
                }).catch(err => {
                    if(showError){
                        setPaymentError(ErrorClass.FAILED_INITIALIZATION)
                        setButtonLoading(false)
                    }
                })
        } catch (err) {
            setPaymentError(ErrorClass.FAILED_INITIALIZATION)
            setButtonLoading(false)
        }
    }

    const requestTransferPayment = async () =>{
        setIsLoading(true)
        // Make a request for transfer payment
        try {
            const res = await requestPayment(paymentParams, FundingSourceType.TRANSFER)

            // Successful
            if (res.status === "PENDING") {
                // Transfer details retrieved
                setTransferDetails({
                    accountNumber: res.responseData.AccountNumber,
                    accountName: merchantDetails.name,
                    requestedAt: new Date()
                })
                showTransferDetails()
            }else{
                showFailed(res.statusMessage? res.statusMessage : ErrorClass.FAILED_INITIALIZATION)
            }
        } catch (err) {
            showFailed(ErrorClass.FAILED_INITIALIZATION)
        } finally {
            setIsLoading(false)
        }
    }

    const onTimerCompletion = ()=>{
        setTransferDetails({
            accountNumber: '',
            accountName: '',
            bankName: '',
            requestedAt: null
        })
        showFailed()
    }

    const setTimerCount = () => {
        if (transferDetails.requestedAt) {
            // Calculate the difference between otpRequestedAt and the current date in seconds
            const timeDiffInSeconds = Math.floor((new Date() - transferDetails.requestedAt) / 1000);

            // Calculate the seconds remaining on the timer, if any
            const secondsRemaining = defaultTimerCountInSeconds - timeDiffInSeconds;

            // Return either 0 or the remaining seconds, whichever is greater
            return secondsRemaining > 0 ? secondsRemaining : 0;
        }

        // If otpRequestedAt is false, return the default timer count in seconds
        return defaultTimerCountInSeconds;
    }
    
    return (
        <>
            {isLoading ? (
                <Loader fillParent={true} logoSize={'0'}/>
            ) : (
                <>
                    <SuccessModal
                        show={transferStatus.status === TransferStatus.SUCCESS}
                        onHide={() => {}}
                        paymentDetails={{
                            refNo: paymentParams.payment_reference,
                            transactionType: 'Transfer',
                            amount: paymentParams.amount,
                            fee: transferFeeDetails.amount,
                            tax: transferFeeDetails.taxes? transferFeeDetails.taxes.vat : 0.00,
                            total: transferFeeDetails.total
                            // extraDetails: {
                            //     'Payer Bank Name': 'GT Bank',
                            //     'Payer Account Name': 'John Doe',
                            //     'Payer Account Number': '0012344432'
                            // },
                        }}
                    />
                    {transferStatus.status === TransferStatus.FAILED ? (
                        <FailedTransfer />
                    ) : null}
                    {transferStatus.status === TransferStatus.INITIALIZED ? (
                        <div className="box">
                            <div>
                                <PageHeading
                                    title="Pay with transfer"
                                    description="The exact amount on this page should be transferred"
                                />

                                { paymentError ?
                                    <FormError className={'mt-1'}>{paymentError}</FormError>
                                    : null
                                }

                                <div className="grey-container mt-1">
                                    <div className="darker-grey-container justify-content-center mb-2"
                                    style = {{padding: paymentError? '4px': '10px'}}>
                                        <h1 className="text-md grey-color">Amount</h1>
                                        <h1 className="text-xl fw-bold secondary-color text-spacing mb-2">
                                            {'₦' + transferFeeDetails.total}
                                            <CopySvg
                                                onClick={() => {
                                                    copyText(transferFeeDetails.total);
                                                }}
                                                className="copy-button mx-1 mb-1"
                                            />
                                        </h1>

                                        <h1 className="text-md grey-color">
                                            Account Number
                                        </h1>
                                        <h1 className="text-xl fw-bold secondary-color text-spacing mb-0">
                                            {transferDetails.accountNumber}
                                            <CopySvg
                                                onClick={() => {
                                                    copyText(
                                                        transferDetails.accountNumber
                                                    );
                                                }}
                                                className="copy-button mx-1 mb-1"
                                            />
                                        </h1>
                                    </div>

                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="text-sm grey-color">
                                            Bank Name
                                        </p>
                                        <p className="text-md fw-bold">Paga</p>
                                    </div>
                                <p className="text-md fw-bold">{transferDetails.bankName}</p>

                                    <div className="d-flex justify-content-between">
                                        <p className="text-sm grey-color">
                                            Beneficiary Name
                                        </p>
                                        <p className="text-md fw-bold">
                                            {transferDetails.accountName}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="grey-color text-sm mb-1">
                                    Please get in touch with your bank if the transaction isn't received in{" "}
                                    <CountdownTimer
                                        seconds={setTimerCount()}
                                        onCompletion={onTimerCompletion}
                                    />
                                </div>

                                <ButtonWidget
                                    className="modal-btn"
                                    onClick={checkTransferPaymentStatus}
                                    loading={buttonLoading}
                                >
                                    {'I have transferred ₦' + transferFeeDetails.total}
                                </ButtonWidget>
                                <p className="page-text text-sm mt-1">
                                    {`Includes fee ₦${transferFeeDetails.amount}`}
                                </p>
                            </div>
                            <Toaster />
                        </div>
                    ) : null}
                </>
            )}
        </>
    );

}

export default TransferPayment
