import ButtonWidget from "../../../components/elements/ButtonWidget"
import PaymentFailedGif from "../../../assets/gif/paymentfailed.gif";
import PageHeading from "../../../components/elements/PageHeading";
import {ErrorClass} from "../../../constants/ErrorConstants";
import {usePagaStatus} from "../../../contexts/PagaStatusContext";

const FailedAppPayment = ()=>{
    const {showPayWithApp, pagaStatus} = usePagaStatus()

    return (
        <div className="box">
            <div>
                <PageHeading title="Unable to process your request"
                             description="" className="mb-2"/>

                <img src={PaymentFailedGif} width={124} height={124} alt="Payment Failed"/>

                <div className="grey-container align-items-center">
                    <div className="text-md">
                        {pagaStatus.message ? pagaStatus.message :
                            ErrorClass.FAILED_PAYMENT_STATUS_CHECK
                        }
                    </div>
                </div>
            </div>

            <div>
                <ButtonWidget className="modal-btn" onClick={()=>{
                    showPayWithApp()
                }}>
                    Retry
                </ButtonWidget>
            </div>
        </div>
    )
}

export default FailedAppPayment
