import ButtonWidget from "../../components/elements/ButtonWidget"
import {usePagaStatus} from "../../contexts/PagaStatusContext";
import FormError from "../../components/elements/FormError";
import Loader from "../../components/elements/Loader";
import PageHeading from "../../components/elements/PageHeading";
import {useEffect, useState} from "react";
import {usePayment} from "../../contexts/PaymentContext";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";
import {checkPaymentStatus, handlePaymentStatus} from "../../services/PaymentService";
import {ErrorClass} from "../../constants/ErrorConstants";
import CountdownTimer from "../../components/elements/CountdownTimer";

const PayWithApp = ()=>{
    const defaultTimerCountInSeconds = 60 * 30
    const pollingInterval = window.env.POLLING_DURATION_SECS * 1000
    const {buttonLoading, setButtonLoading} = useState(false)
    const {showSuccessfulPayment, showAuth, showFailed, showLoggedOut, setAppUser, loginInfo,
        appPaymentRequestedAt} = usePagaStatus()
    const {paymentParams} = usePayment()
    const [paymentError, setPaymentError] = useState(null)
    let timer

    useEffect(() => {
        timer = setTimeout(startPolling, pollingInterval);
        setAppUser(loginInfo)

        return () => clearTimeout(timer);
    }, [appPaymentRequestedAt]);

    const startPolling = () => {
        checkAppPaymentStatus(false).then(()=>{
            // Schedule the next poll after the interval duration
            timer = setTimeout(startPolling, pollingInterval);
        });
    };

    const onTimerCompletion = ()=>{
        checkAppPaymentStatus(false)
        showFailed()
    }

    const setTimerCount = () => {
        if (appPaymentRequestedAt) {
            // Calculate the difference between RequestedAt and the current date in seconds
            const timeDiffInSeconds = Math.floor((new Date() - appPaymentRequestedAt) / 1000);

            // Calculate the seconds remaining on the timer, if any
            const secondsRemaining = defaultTimerCountInSeconds - timeDiffInSeconds;

            // Return either 0 or the remaining seconds, whichever is greater
            return secondsRemaining > 0 ? secondsRemaining : 0;
        }

        // If RequestedAt is false, return the default timer count in seconds
        return defaultTimerCountInSeconds;
    }

    const checkAppPaymentStatus = async (setError = true)=>{
        if(setError) setButtonLoading(true)

        try {
            checkPaymentStatus(paymentParams, FundingSourceType.APP)
                .then(response => response.json())
                .then((res) =>{
                    handlePaymentStatus(res, setPaymentError, showSuccessfulPayment, setError)
                    if(setError) setButtonLoading(false)
                }).catch(() => {
                    if(setError){
                        setPaymentError(ErrorClass.FAILED_INITIALIZATION)
                        setButtonLoading(false)
                    }
                })
        } catch (err) {
            //console.log(err)
            setPaymentError(ErrorClass.FAILED_INITIALIZATION)
            setButtonLoading(false)
        }
    }

    return (
        <div className="box">
            <div>
                <PageHeading className="mb-2" title=" " description=""
                             hasBackButton={true} onBackButtonClicked={()=>showLoggedOut()}/>
                <h5 className="modal-heading-text">Transaction Authorization</h5>
                <h6 className="modal-text mt-2 light-text">
                    A notification has been sent to your Paga app, login to authorize transaction
                </h6>

                { paymentError ?
                    <FormError>{paymentError}</FormError>
                    : null }
                <Loader text=""/>

            </div>

            <div>
                <div className="grey-color text-sm mb-1">
                    This will expire in{" "}
                    <CountdownTimer
                        seconds={setTimerCount()}
                        onCompletion={onTimerCompletion}
                    />
                </div>

                <ButtonWidget className="modal-btn"
                              onClick={checkAppPaymentStatus}
                              loading={buttonLoading}>
                    I have made payment
                </ButtonWidget>

                <div className="modal-text text-sm mt-1">
                    Don't have access to your phone?
                    <button className="border-0 bg-transparent secondary-color text-sm"
                            onClick={showAuth}
                    >
                        Login to pay
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PayWithApp
