import {createContext, useContext, useState} from "react"

export class UssdStatus {
    static UNINITIALIZED = "uninitialized"
    static INITIALIZED = "initialized"
    static FAILED = "failed"
    static SUCCESS = "success"

    constructor(type) {
        this.type = type
    }
}

export const UssdContext = createContext(null)

export const UssdContextProvider = ({ children }) => {
    const [ussdStatus, setUssdStatus] = useState({
        status: UssdStatus.UNINITIALIZED,
        message: ''
    })
    const [banks, setBanks] = useState(null)
    const [ussdDetails, setUssdDetails] = useState({
        ussdShortCode: '',
        paymentReference: '',
        requestedAt: null
    })

    const showSelectBank = () => {
        setUssdStatus({
            status: UssdStatus.UNINITIALIZED,
            message: ''
        })
    }

    const showUssdDetails = () => {
        setUssdStatus({
            status: UssdStatus.INITIALIZED,
            message: ''
        })
    }

    const showFailed = (message) => {
        setUssdStatus({
            status: UssdStatus.FAILED,
            message: message
        })
    }

    const showSuccess = () => {
        setUssdStatus({
            status: UssdStatus.SUCCESS,
            message: ''
        })
    }

    const setErrorMessage = (message) => {
        setUssdStatus({
            status: ussdStatus.status,
            message: message
        })
    }

    const value = {ussdStatus, setErrorMessage, showFailed, showSuccess, showSelectBank, showUssdDetails,
    banks, setBanks, ussdDetails, setUssdDetails}

    return(
        <UssdContext.Provider value={value}> {children} </UssdContext.Provider>
    )
}

export default UssdContext

export const useUssdStatus = ()=>{
    return useContext(UssdContext)
}