import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  highlight: PropTypes.bool,
}

const defaultProps = {
  title: '',
  value: '',
  highlight: false
}

const ReceiptItem = ({
  className,
  title,
  value,
  highlight,
  children,
  ...props
}) => {

  const classes = classNames(
    'receipt-item',
    'text-justify-content-between',
    'justify-content-between',
    'align-content-center',
    'w-100',
    'd-flex',
    className
  );

  const highlightStyle = {
    color: highlight ? `#5c91ee` : '',
  };

  return (
      <>
        {value?
            <div className={classes}>
              <div className="text-sm page-text grey-color text-start" style={highlightStyle}>
                {title}
              </div>

              <div className="text-md fw-bold modal-text text-end" style={highlightStyle}>
                {value}
              </div>
            </div>
            : null
        }
      </>

  );
}

ReceiptItem.propTypes = propTypes;
ReceiptItem.defaultProps = defaultProps;

export default ReceiptItem;