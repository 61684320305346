import classNames from "classnames";
import PropTypes from 'prop-types';
import {ReactComponent as RadioOnSvg} from "../../assets/svg/radio-checked.svg";
import {ReactComponent as RadioOffSvg} from "../../assets/svg/radio.svg";
import {ReactComponent as RadioHoverSvg} from "../../assets/svg/radio-hover.svg";

const propTypes = {
    onToggle: PropTypes.func,
    keyValue: PropTypes.any,
    radioValue: PropTypes.any,
    isHovering: PropTypes.bool,
    disabled: PropTypes.bool,
}

const defaultProps = {
    onToggle: ()=>{},
    keyValue: 'keyValue',
    radioValue: 'radioValue',
    isHovering: false,
    disabled: false,
}

const RadioButton = ({
    onToggle,
    keyValue,
    radioValue,
    className,
    disabled,
    isHovering
}) => {
    const classes = classNames(
        className,
        'border-0',
        'px-0',
        'bg-transparent'
    )

    const handleClick = ()=>{
        onToggle()
    }

    const currentSvg = ()=>{
        if(disabled){
            return <RadioOffSvg/>
        }

        if(keyValue !== radioValue){
            if(isHovering){
                return <RadioHoverSvg/>
            }else{
                return <RadioOffSvg/>
            }
        }else{
            return <RadioOnSvg/>
        }
    }

    return (
        <button className={classes}
                onClick={handleClick}
                disabled={disabled}
        >
            {currentSvg()}
        </button>
    );
}

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;

export default RadioButton;