import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import RadioButton from "./RadioButton";
import {useState} from "react";

const propTypes = {
    leading: PropTypes.object,
    trailing: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    keyValue: PropTypes.any,
    radioValue: PropTypes.any
}

const defaultProps = {
    leading: <></>,
    trailing: <></>,
    disabled: false,
    onClick: ()=>{},
    keyValue: 'key',
    radioValue: 'value'
}

const PagaOption = ({
    keyValue,
    radioValue,
    leading,
    trailing,
    onClick,
    children,
    disabled,
    className
}) => {
    const [isHovering, setIsHovering] = useState(false)
    const setHovering = (boolean) => {if(disabled) setIsHovering(boolean)}

    const classes = classNames(
        className,
        'd-flex',
        'justify-content-between',
        'align-items-center',
        !disabled && 'clickable'
    );
    
    const handleOnSelected = () => {
        onClick()
    }

  return (
    <>
        <div className={classes}>
            <div className="paga-option d-flex align-items-center text-black"
                 onClick={handleOnSelected}
                 onMouseEnter={() => setHovering(true)}
                 onMouseLeave={() => setHovering(false)}
            >
                <RadioButton keyValue={keyValue} radioValue={radioValue}
                             isHovering={isHovering} disabled={disabled}/>
                <div className="mx-1"/>
                {leading}
                {children}
            </div>

            {trailing}
        </div>
        <hr className="my-0"/>
    </>
  );
}

PagaOption.propTypes = propTypes;
PagaOption.defaultProps = defaultProps;

export default PagaOption;