import {useState} from "react"
import {usePayment} from "../contexts/PaymentContext"
import classNames from "classnames"
import {useUssdStatus} from "../contexts/UssdContext"
import {requestPayment} from "../services/PaymentService"
import {FundingSourceType} from "../constants/PaymentRequestConstants"
import {ErrorClass} from "../constants/ErrorConstants";
import SelectBank from "./SelectBank";

const SelectUssdBank = ()=>{
    const {paymentParams} = usePayment()
    const {showUssdDetails, setUssdDetails, showFailed, banks} = useUssdStatus()

    const requestUssdPayment = async (selectedBankId) =>{
        // Make a request for USSD payment
        try {
            const res = await requestPayment(paymentParams, FundingSourceType.USSD, {
                "bankId": selectedBankId,
                "isUssdCodeLongLived": false
            })

            // Successful
            if (res.status === "PENDING") {
                // Ussd details retrieved
                setUssdDetails({
                    ussdShortCode: res.responseData.USSDShortCode,
                    paymentReference: res.responseData.PaymentReference,
                    requestedAt: new Date()
                })
                showUssdDetails()
            }else{
                showFailed(res.statusMessage? res.statusMessage : ErrorClass.FAILED_INITIALIZATION)
            }
        } catch (err) {
            showFailed(ErrorClass.FAILED_INITIALIZATION)
        }
    }

    return (
        <SelectBank channel={FundingSourceType.USSD} banks={banks}
                    onBankSelected={(bankId)=>requestUssdPayment(bankId)}/>
    )
}

export default SelectUssdBank
