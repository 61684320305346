import {Col, Form, Row} from "react-bootstrap"
import {CARDARR, CARDICON, CARDNUMBERMASK, VERVECARDNUMBERMASK, CVCMASK, EXPIRYDATEMASK} from "../../constants/CardConstants"
import FormInputError from "./FormInputError"
import {useCard} from "../../contexts/CardContext"
import {cardNumberValidation, cardExpiryDateValidation, minLength, findCardType} from "../../validators/CardValidator"
import MaskedInputField from "./MaskedInputField"
import {useRef} from "react"
import {usePayment} from "../../contexts/PaymentContext";

const CardPaymentForm = ({
    className,
    ...props
}) => {
    const {card, setCard, cardType, setCardType, error, setError} = useCard()
    const cardNumberRef = useRef(null)
    const expiryDate = useRef(null)
    const cvcRef = useRef(null)
    const {paymentParams} = usePayment()

    const handleInputData = (e) => {
        setCard({ type: e.target.name, data: e.target.value })
        handleValidations(e.target.name, e.target.value, true)
    }

    const handleBlur = (e) => {
        handleValidations(e.target.name, e.target.value, false, true)
    }

    const handleValidations = (type, value, setFocus = false, setErrorText = false) => {
        let errorText
        switch (type) {
            case "cardNumber":
                value = value.replace(/[^\d]/g, "")
                setCardType(findCardType(value, paymentParams.card_scheme.split(',')))
                errorText = value === "" ? "Required" : cardNumberValidation(value, cardType)
                if(setErrorText){
                    setError({ ...error, cardNumberError: errorText })
                }

                if(!setFocus) return
                const cardCompleteLength = cardType === "VERVE" || cardType === ""? 19 : 16
                if(value.length >= cardCompleteLength && errorText === ''){
                    expiryDate.current.inputElement.focus()
                    setError({ ...error, cardNumberError: '' })
                }
                break
            case "expiryDate":
                errorText =
                    value === "" ? "Required" : cardExpiryDateValidation(value)
                if(setErrorText || value.length === 5){
                    setError({ ...error, expiryDateError: errorText })
                }else{
                    setError({ ...error, expiryDateError: ''})
                }

                if(!setFocus) return
                if(value.length === 5 && errorText === ''){
                    cvcRef.current.inputElement.focus()
                    setError({ ...error, expiryDateError: ''})
                }
                break
            case "cvc":
                errorText = value === "" ? "Required" : minLength(3)(value)
                if(setErrorText){
                    setError({ ...error, cvcError: errorText })
                }

                if(!setFocus) return
                if(value.length === 3 && errorText === ''){
                    cvcRef.current.inputElement.blur()
                    setError({ ...error, cvcError: ''})
                }
                break
            default:
                break
        }
    }

    return (
        <Form>
            {/* Card number input*/}
            <Form.Group className="mb-3" controlId="formCardNumber">
                <div className="input-container">
                    <MaskedInputField
                        mask={cardType === "VERVE" || cardType === ""? VERVECARDNUMBERMASK: CARDNUMBERMASK}
                        label="Card Number"
                        guide={false}
                        placeholderChar={"\u2000"}
                        placeholder="0000 0000 0000 0000"
                        name="cardNumber"
                        required
                        value={card.cardNumber}
                        onChange={handleInputData}
                        onBlur={handleBlur}
                        error={error.cardNumberError}
                        pattern="\d*"
                        inputMode="numeric"
                        ref={cardNumberRef}
                    />
                    {CARDARR.includes(cardType) && (
                        <img
                            className="card-type"
                            src={CARDICON[cardType]}
                            alt="cardType"
                        />
                    )}
                </div>
                {error.cardNumberError && error.cardNumberError.length > 1 && (
                    <FormInputError>{error.cardNumberError}</FormInputError>
                )}
            </Form.Group>

            <Row>
                {/* Card expiry date input */}
                <Form.Group as={Col} className="zero-right-padding" controlId="formValidTill">
                    <MaskedInputField
                        mask={EXPIRYDATEMASK}
                        label="Expiry Date"
                        guide={false}
                        name="expiryDate"
                        required
                        placeholderChar={"\u2000"}
                        placeholder="MM/YY"
                        value={card.expiryDate}
                        onChange={handleInputData}
                        onBlur={handleBlur}
                        error={error.expiryDateError}
                        pattern="\d*"
                        inputMode="numeric"
                        ref={expiryDate}
                    />
                    {error.expiryDateError && error.expiryDateError.length > 1 && (
                        <FormInputError>{error.expiryDateError}</FormInputError>
                    )}
                </Form.Group>

                {/* Card CVC input */}
                <Form.Group as={Col} controlId="formCVC">
                    <MaskedInputField
                        mask={CVCMASK}
                        label="CVC"
                        guide={false}
                        name="cvc"
                        required
                        placeholderChar={"\u2000"}
                        placeholder="123"
                        value={card.cvc}
                        onChange={handleInputData}
                        onBlur={handleBlur}
                        pattern="\d*"
                        type="password"
                        inputMode="numeric"
                        error={error.cvcError}
                        ref={cvcRef}
                    />
                    {error.cvcError && error.cvcError.length > 1 && (
                        <FormInputError>{error.cvcError}</FormInputError>
                    )}
                </Form.Group>
            </Row>
        </Form>
    )
}

export default CardPaymentForm