export class ErrorClass {
    static FAILED_INITIALIZATION = "Unable to complete transaction, try again later.";
    static FAILED_INITIALIZATION_HEADING = "Unable to process your request";
    static FAILED_CARD_PAYMENT = "Unable to complete transaction, try again later";
    static FAILED_TRANSFER_PAYMENT = 'We could not confirm your payment, If you have made the payment already, please contact your bank for a refund.';
    static FAILED_AGENT_PAYMENT = 'We could not confirm your payment, If you have made the payment already, please contact your agent for a refund.';
    static FAILED_PAYMENT_STATUS_CHECK = 'Unable to confirm payment';
    static FAILED_OTP_VERIFICATION = 'Unable to verify OTP';
    static PENDING_PAYMENT_STATUS = 'Payment not yet received';
    static FAILED_PAY_WITH_APP = "Unable to complete transaction, try again later";
    static FAILED_AUTHENTICATION = "Please try again or use another payment option";

    static NO_MERCHANT_DETAILS = "Failed to get fee details";
    static NO_FEE_DETAILS = "Failed to get merchant details";

    static MISSING_EMAIL = "Missing email parameter.";
    static MISSING_PUBLIC_ID = "Missing public id parameter.";
    static MISSING_AMOUNT = "Missing amount parameter.";

    constructor(type) {
        this.type = type;
    }
}