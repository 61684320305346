import {createContext, useContext, useState} from "react"
import {ErrorClass} from "../constants/ErrorConstants"
import {PaymentStatus} from "./PaymentStatusContext"

export class PagaStatus {
    static OTP = "OTP"
    static PIN = "PIN"
    static SECURE3D = "3DS"
    static AUTHENTICATED = "logged-in"
    static UNAUTHENTICATED = "logged-out"
    static AUTH_SERVER = "auth-server"
    static PASSWORD_AUTH = "password"
    static APP = "app"
    static SUCCESS = "success"
    static FAILED_APP_PAYMENT = "failed"

    constructor(type) {
        this.type = type
    }
}

export const PagaStatusContext = createContext(null)

export const PagaStatusContextProvider = ({ children }) => {
    const [pagaStatus, setPagaStatus] = useState({
        status: PagaStatus.UNAUTHENTICATED,
        message: ''
    })
    const [loginInfo, setLoginInfo] = useState('')
    const [appUser, setAppUser] = useState(null)
    const [fundingSources, setFundingSources] = useState([])
    const [pagaOtpRequestedAt, setPagaOtpRequestedAt] = useState(null)
    const [appPaymentTransactionNo, setAppPaymentTransactionNo] = useState(null)
    const [selectedFundingSource, setSelectedFundingSource] = useState(null)
    const [appPaymentRequestedAt, setAppPaymentRequestedAt] = useState(null)

    const showAuth = () => {
        setPagaStatus({
            status: PagaStatus.AUTH_SERVER,
            message: ''
        })
    }

    const showPasswordAuth = () => {
        setPagaStatus({
            status: PagaStatus.PASSWORD_AUTH,
            message: ''
        })
    }

    const showPayWithApp = () => {
        setPagaStatus({
            status: PagaStatus.APP,
            message: ''
        })
    }

    const showPayWithPaga = (paymentSources = fundingSources, message = '') => {
        if(paymentSources === null){
            setPagaStatus({
                status: PagaStatus.UNAUTHENTICATED,
                message: ErrorClass.FAILED_AUTHENTICATION
            })
        }else{
            //sort funding sources by type. In this order - Paga, card, bank
            setFundingSources(sortFundingSourceByType(paymentSources))

            setPagaStatus({
                status: PagaStatus.AUTHENTICATED,
                message: message
            })
        }
    }

    const showPreviousPage = () => {
        setPagaStatus(prevStatus => ({
            ...prevStatus,
            message: prevStatus.status === PaymentStatus.OTP? prevStatus.message : ''
        }))
    }

    const showFailed = (message = '') => {
        setPagaStatus({
            status: PagaStatus.FAILED_APP_PAYMENT,
            message: message
        })
    }

    const showLoggedOut = (message = '') => {
        setPagaStatus({
            status: PagaStatus.UNAUTHENTICATED,
            message: message
        })
        setAppPaymentTransactionNo(null)
        setAppPaymentRequestedAt(null)
    }

    const showSuccessfulPayment = () => {
        setPagaStatus({
            status: PagaStatus.SUCCESS,
            message: ''
        })
    }

    const setErrorMessage = (message) => {
        setPagaStatus(prevStatus => ({
            ...prevStatus,
            message: message
        }))
    }

    const showOTPAuth = (message) => {
        setPagaStatus({
            status: PagaStatus.OTP,
            message: message
        })
    }

    const showPINAuth = () => {
        setPagaStatus({
            status: PagaStatus.PIN,
            message: ''
        })
    }

    const showSecure3DAuth = () => {
        setPagaStatus({
            status: PagaStatus.SECURE3D,
            message: ''
        })
    }

    const value = {loginInfo, setLoginInfo, appUser, setAppUser, pagaStatus, setErrorMessage, showPayWithPaga,
        showLoggedOut, showAuth, showPasswordAuth, showPayWithApp, showSuccessfulPayment, appPaymentTransactionNo,
        setAppPaymentTransactionNo, appPaymentRequestedAt, setAppPaymentRequestedAt, showFailed, fundingSources,
        setFundingSources, showOTPAuth, showPINAuth, showSecure3DAuth, showPreviousPage, selectedFundingSource,
        setSelectedFundingSource, pagaOtpRequestedAt, setPagaOtpRequestedAt}

    return(
        <PagaStatusContext.Provider value={value}> {children} </PagaStatusContext.Provider>
    )
}

export default PagaStatusContext

const sortFundingSourceByType = (fundingSources)=>{
    const typeOrder = {
        "PAGA": 1,
        "CARD": 2,
        "BANK_ACCOUNT": 3
    }

    fundingSources.sort((a, b) => {
        const typeA = typeOrder[a.type] || Infinity
        const typeB = typeOrder[b.type] || Infinity

        if (typeA < typeB) {
            return -1
        } else if (typeA > typeB) {
            return 1
        } else {
            return 0
        }
    })

    return fundingSources
}

export const usePagaStatus = ()=>{
    return useContext(PagaStatusContext)
}