import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import RippleGif from "../../assets/gif/ripple.gif";
import {ReactComponent as LogoSvg} from "../../assets/svg/logo/main.svg";

const propTypes = {
    size: PropTypes.string,
    logoSize: PropTypes.string,
    text: PropTypes.string,
    fillParent: PropTypes.bool
}

const defaultProps = {
    size: '200px',
    logoSize: '45px',
    text: 'Loading...Please wait.',
    fillParent: false
}

const Loader = ({
    text,
    size,
    logoSize,
    fillParent,
    className
}) => {

    const classes = classNames(
        'page-text',
        'bg-transparent',
        fillParent && 'd-flex flex-column h-100 justify-content-center',
        className
    );

  return (
      <div className={classes}>
          <div className="ripple-container bg-transparent">
              <img width={size} height={size} src={RippleGif} className="ripple" alt="Pay with app"/>
              <LogoSvg className="ripple-logo" style={{width: logoSize}}/>
          </div>
          {text}
      </div>
  );
}

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;