import ButtonWidget from "../../components/elements/ButtonWidget"
import PageHeading from "../../components/elements/PageHeading";
import {ReactComponent as MainLogo} from "../../assets/svg/logo/main.svg";
import {usePagaStatus} from "../../contexts/PagaStatusContext";
import {ReactComponent as EmailSvg} from "../../assets/svg/email.svg";
import MaskedInputField from "../../components/elements/MaskedInputField";
import {useState} from "react";
import EyeToggle from "../../components/elements/EyeToggle";

const PasswordAuthentication = ()=>{
    const {showPayWithPaga, loginInfo} = usePagaStatus()
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')

    const toggleShowPassword = ()=>{
        setShowPassword(!showPassword)
    }

    const handleInputData = (e) => {
        setPassword(e.target.value)
    }

    return (
        <div className="box">
            <div>
                <PageHeading className="mb-3" title="" description="Log into your Paga account">
                    <MainLogo className="mb-2"/>
                </PageHeading>

                <div className="d-flex align-items-center mb-2">
                    <EmailSvg/>
                    <div className="mx-1 modal-text text-font-md">{loginInfo}</div>
                </div>

                <div className="input-container">
                    <MaskedInputField
                        mask={false}
                        label="Password"
                        guide={false}
                        className="right-padding"
                        placeholderChar={"\u2000"}
                        placeholder="Enter Password"
                        name="loginInfo"
                        required
                        type={showPassword ? 'text': 'password'}
                        value={password}
                        onChange={handleInputData}
                        // onBlur={handleBlur}
                    />

                    <EyeToggle className="view-icon" onToggle={toggleShowPassword} showValue={showPassword}/>

                </div>
            </div>

            <div>
                <ButtonWidget className="modal-btn" disabled={!password} onClick={showPayWithPaga}>
                    Login
                </ButtonWidget>
            </div>
        </div>
    )
}

export default PasswordAuthentication
