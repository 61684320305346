import {useSecure3D} from "../../contexts/Secure3DContext";
import {useEffect} from "react";
import {checkPaymentStatus} from "../../services/PaymentService";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";
import {usePayment} from "../../contexts/PaymentContext";
import {PaymentStatus, usePaymentStatus} from "../../contexts/PaymentStatusContext";
import {PagaStatus, usePagaStatus} from "../../contexts/PagaStatusContext";
import {ErrorClass} from "../../constants/ErrorConstants";

const Secure3DAuthentication = ()=>{
    const {secure3DWidget} = useSecure3D()
    const {paymentParams} = usePayment()
    const pollingInterval = window.env.POLLING_DURATION_SECS * 1000
    const {paymentStatus, showSuccessModal, showFailedModal} = usePaymentStatus()
    const {pagaStatus, showSuccessfulPayment, showPayWithPaga} = usePagaStatus()
    let timer

    useEffect(() => {
        timer = setTimeout(startPolling, pollingInterval);

        return ()=>{
            clearTimeout(timer)
        }

    }, []);

    const onIframeLoad = () => {
        const iframe = document.getElementById('3ds-auth')
        const iframeOrigin = new URL(iframe.src).origin

        // Add event listener for messages received from the auth server iframe
        window.addEventListener('message', function (event) {
            // Check if the event originated from the iframe after authentication
            if (event.origin === iframeOrigin) {
                // Remove the auth server iframe from the document
                if (iframe && event.data.status === '3ds-redirect') {
                    try {
                        document.body.removeChild(iframe)
                    }catch (e){}
                }
            }
        })
    }

    const check3DSPaymentStatus = async ()=>{
        try {
            checkPaymentStatus(paymentParams, FundingSourceType.DEBIT_CARD)
                .then(response => response.json())
                .then((res)=>{
                    // Successful
                    if (res.status === "SUCCESS" || res.status === "SUCCESSFUL") {
                        onSuccess()
                    }

                    if (res.status === "FAILED") {
                        onFailed(res.statusMessage)
                    }
                })
        } catch (err) {
            //console.log(err)
        }
    }

    const startPolling = () => {
        check3DSPaymentStatus().then(()=>{
            // Schedule the next poll after the interval duration
            timer = setTimeout(startPolling, pollingInterval);
        });
    };

    const onSuccess = ()=>{
        if(paymentStatus.status === PaymentStatus.SECURE3D){
            showSuccessModal()
        }

        if(pagaStatus.status === PagaStatus.SECURE3D){
            showSuccessfulPayment()
        }
    }

    const onFailed= (message)=>{
        if(paymentStatus.status === PaymentStatus.SECURE3D){
            showFailedModal(message? message: ErrorClass.FAILED_CARD_PAYMENT)
        }

        if(pagaStatus.status === PagaStatus.SECURE3D){
            showPayWithPaga(undefined, message? message: ErrorClass.FAILED_CARD_PAYMENT)
        }
    }


    return (
        <div className="w-100">
            <iframe
                className="secure3ds"
                id="3ds-auth"
                title="3ds-auth"
                width="100%"
                height="100%"
                src={secure3DWidget.url}
                srcDoc={secure3DWidget.url ? undefined : secure3DWidget.html}
                onLoad={onIframeLoad}
            ></iframe>
        </div>
    )
}

export default Secure3DAuthentication
