import axios from "axios"
import {getBaseUrl, getRequestPayload} from "./BaseService"
import queryString from 'query-string'
import {FundingSourceType} from "../constants/PaymentRequestConstants"
import {ErrorClass} from "../constants/ErrorConstants"

axios.defaults.withCredentials = true

export async function requestPayment(paymentParams, fundingSourceType, payload, customerIdentifier) {
    let urlPrefix = ''
    switch (fundingSourceType){
        case FundingSourceType.PAGA:
            urlPrefix = 'paga'
            break
        case FundingSourceType.AGENT:
            urlPrefix = 'agent'
            break
        case FundingSourceType.USSD:
            urlPrefix = 'ussd'
            break
        case FundingSourceType.TRANSFER:
            urlPrefix = 'transfer'
            break
        case FundingSourceType.APP:
            urlPrefix = 'app'
            break
        default:
            break
    }
    const url = "/" + urlPrefix + "/charge/v1.0"

    const body = getRequestPayload(paymentParams, fundingSourceType, payload, customerIdentifier)

    const response = await axios.post(getBaseUrl() + url, body)

    return response.data
}

export function mergeObjectsWithPriority(obj1, obj2) {
    // Create a new object to hold the merged properties
    const mergedObj = {}

    // Iterate over the keys in obj1 and add them to the merged object
    for (const key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {
            mergedObj[key] = obj1[key]
        }
    }

    // Iterate over the keys in obj2 and add them to the merged object if they don't exist in obj1
    for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key) && !Object.prototype.hasOwnProperty.call(mergedObj, key)) {
            mergedObj[key] = obj2[key]
        }
    }

    // Return the merged object
    return mergedObj
}

export async function getPaymentFees(paymentParams, payload = []){
    const url = "/merchant/fee/v1.0"

    const body = getRequestPayload(paymentParams, null, payload)

    const response = await axios.post(getBaseUrl() + url, body)
    return response.data
}

export async function getBanks(){
    const url = "/funding/institutions/v1.0"

    const response = await axios.post(getBaseUrl() + url, {})
    return response.data
}

export async function checkPaymentStatus(paymentParams, fundingSourceType = null) {
    const url = '/charge/status/v1.0'

    const queryParams = {
        processReferenceNumber: paymentParams.payment_reference,
        fundingSourceType: fundingSourceType,
        merchantAccountId: paymentParams.public_key,
        callbackUrl: paymentParams.callbackUrl
    }

    const queryStringParams = queryString.stringify(queryParams)
    const requestUrl = `${getBaseUrl()}${url}?${queryStringParams}`

    const requestOptions = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json'
        }
    }

    return fetch(requestUrl, requestOptions)
}

export const handlePaymentStatus = (res, setPaymentError, showSuccess, showError) => {
    // Successful
    if (res.status === "SUCCESS" || res.status === "SUCCESSFUL") {
        showSuccess();
    }

    if (!showError) {
        return;
    }

    if (res.status === "PENDING") {
        setPaymentError(res.statusMessage ? res.statusMessage : ErrorClass.PENDING_PAYMENT_STATUS);
    } else {
        setPaymentError(res.statusMessage ? res.statusMessage : ErrorClass.FAILED_PAYMENT_STATUS_CHECK);
    }
};