import ButtonWidget from "../../../components/elements/ButtonWidget"
import PaymentFailedGif from "../../../assets/gif/paymentfailed.gif"
import PageHeading from "../../../components/elements/PageHeading"
import {usePagaAgentStatus} from "../../../contexts/PagaAgentContext"
import {ErrorClass} from "../../../constants/ErrorConstants";

const FailedAgentPayment = ()=>{
    const {loadPagaAgentDetails, pagaAgentStatus} = usePagaAgentStatus()

    return (
        <div className="box">
            <div>
                <PageHeading title="Pay with agent"
                             description="" className="mb-2"/>

                <img src={PaymentFailedGif} width={124} height={124} alt="Payment Failed"/>

                <div className="grey-container align-items-center">
                    {
                        pagaAgentStatus.message === ErrorClass.FAILED_INITIALIZATION &&
                        <div className="text-xl fw-bold">
                            {ErrorClass.FAILED_INITIALIZATION_HEADING}
                        </div>
                    }

                    <div className="text-md grey-color">
                        {pagaAgentStatus.message ? pagaAgentStatus.message :
                            ErrorClass.FAILED_AGENT_PAYMENT
                        }
                    </div>
                </div>
            </div>

            <div>
                <ButtonWidget className="modal-btn"  onClick={()=>{loadPagaAgentDetails()}}>
                    Retry
                </ButtonWidget>
            </div>
        </div>
    )
}

export default FailedAgentPayment
