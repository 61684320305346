import OtherPaymentItem from "../components/elements/OtherPaymentItem"
import PageHeading from "../components/elements/PageHeading"
import {ReactComponent as BankSvg} from "../assets/svg/bank.svg"
import {ReactComponent as HashSvg} from "../assets/svg/hash.svg"
import {ReactComponent as SmallLogo} from "../assets/svg/logo/small1.svg"
import {ReactComponent as PagaAgentSvg} from "../assets/svg/paga-agent.svg"
import { useState, useEffect } from 'react'
import {ReactComponent as MoneySvg} from "../assets/svg/money.svg"
import {useMenuSelection} from "../contexts/MenuSelectionContext";

const OtherPayments = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const {channels, secondaryChannels} = useMenuSelection()

    function getLogo(channel) {
        switch (channel) {
            case 'CARD':
                return MoneySvg;
            case 'PAGA':
                return SmallLogo;
            case 'TRANSFER':
                return BankSvg;
            case 'AGENT':
                return PagaAgentSvg;
            case 'USSD':
                return HashSvg;
            default:
                return MoneySvg;
        }
    }

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <PageHeading title="More ways to Pay" description="Select a payment option"
                         showChangeLink={false} showBackLink={true}/>

            {width > 720 ? (
                <div className="grid-container mt-3">
                    {secondaryChannels().map(channel => (
                        <OtherPaymentItem
                            key={channel}
                            text={channel === 'AGENT'? 'Paga Agent' : channel}
                            link={channel.toLowerCase()}
                            logo={getLogo(channel)}
                            formatText={channel !== 'USSD'}
                        />
                    ))}
                </div>
            ) : (
                <div className="grid-container mt-3">
                    {channels.map(channel => (
                        <OtherPaymentItem
                            key={channel}
                            text={channel.toUpperCase()  === 'AGENT'? 'Paga Agent' : channel}
                            link={channel.toLowerCase()}
                            logo={getLogo(channel)}
                            formatText={channel !== 'USSD'}
                        />
                    ))}
                </div>
            )}
        </>
    )
}

export default OtherPayments