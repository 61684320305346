import ButtonWidget from "../../components/elements/ButtonWidget"
import {ReactComponent as CopySvg} from '../../assets/svg/copy.svg'
import FormError from "../../components/elements/FormError"
import PageHeading from "../../components/elements/PageHeading"
import CountdownTimer from "../../components/elements/CountdownTimer"
import copy from 'copy-to-clipboard'
import toast, { Toaster } from 'react-hot-toast'
import {usePayment} from "../../contexts/PaymentContext"
import SuccessModal from "../SuccessModal"
import {PagaAgentStatus, usePagaAgentStatus} from "../../contexts/PagaAgentContext"
import FailedAgentPayment from "./failed/FailedAgentPayment"
import {useEffect, useState} from "react";
import {checkPaymentStatus, handlePaymentStatus, requestPayment} from "../../services/PaymentService";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";
import {ErrorClass} from "../../constants/ErrorConstants";
import {useMerchant} from "../../contexts/MerchantContext";
import Loader from "../../components/elements/Loader";
import {TransferStatus} from "../../contexts/TransferStatusContext";

const PagaAgentPayment = ()=>{
    const {paymentParams} = usePayment()
    const {pagaAgentStatus, showFailed, showSuccess, pagaAgentDetails, showPagaAgentDetails,
        setPagaAgentDetails} = usePagaAgentStatus()
    const defaultTimerCountInSeconds = 60*60*24 - 1
    const pollingInterval = window.env.POLLING_DURATION_SECS * 1000
    const [buttonLoading, setButtonLoading] = useState(false)
    const {merchantDetails, getFeeDetailsByChannel} = useMerchant()
    const [isLoading, setIsLoading] = useState(false)
    const [paymentError, setPaymentError] = useState(null)
    const pagaAgentFeeDetails = getFeeDetailsByChannel('AGENT')
    let copiedToast
    let timer

    useEffect(()=>{
        if(!pagaAgentDetails.accountNumber && pagaAgentStatus.status === PagaAgentStatus.UNINITIALIZED){
            requestPagaAgentPayment()
        }else if (pagaAgentStatus.status === PagaAgentStatus.INITIALIZED) {
            // Start the timer only when initialized
            timer = setTimeout(startPolling, pollingInterval);
        }

        return ()=>{
            clearTimeout(timer)
            if(copiedToast){
                toast.dismiss(copiedToast)
            }
        }
    }, [pagaAgentStatus, pagaAgentDetails])

    const startPolling = () => {
        checkPagaAgentPaymentStatus(false).then(() => {
            // Schedule the next poll after the interval duration
            timer = setTimeout(startPolling, pollingInterval);
        });
    };

    const checkPagaAgentPaymentStatus = async (showError = true)=>{
        if(showError) setButtonLoading(true)

        try {
            checkPaymentStatus(paymentParams, FundingSourceType.AGENT)
                .then(response => response.json())
                .then((res) =>{
                    handlePaymentStatus(res, setPaymentError, showSuccess, showError)
                    if(showError) setButtonLoading(false)
                }).catch(() => {
                    if(showError){
                        setPaymentError(ErrorClass.FAILED_INITIALIZATION)
                        setButtonLoading(false)
                    }
                })
        } catch (err) {
            setPaymentError(ErrorClass.FAILED_INITIALIZATION)
            setButtonLoading(false)
        }
    }

    const requestPagaAgentPayment = async () =>{
        setIsLoading(true)
        // Make a request for paga payment payment
        try {
            const res = await requestPayment(paymentParams, FundingSourceType.AGENT)
            // Successful
            if (res.status === "PENDING") {
                // Transfer details retrieved
                setPagaAgentDetails({
                    accountNumber: res.responseData.AccountNumber,
                    accountName: merchantDetails.name,
                    bankName: res.responseData.bankName,
                    requestedAt: new Date()
                })
                showPagaAgentDetails()
            }else{
                showFailed(res.statusMessage? res.statusMessage : ErrorClass.FAILED_INITIALIZATION)
            }
        } catch (err) {
            //console.log(err)
            showFailed(ErrorClass.FAILED_INITIALIZATION)
        } finally {
            setIsLoading(false)
        }
    }

    const copyText = (text) => {
        copy(text)
        if(copiedToast){
            toast.dismiss(copiedToast)
        }
        copiedToast = toast('Copied to clipboard', {
            position: 'bottom-right',
            duration: 2000,
            className: 'page-text',
        })
    }

    const onTimerCompletion = ()=>{
        setPagaAgentDetails({
            accountNumber: '',
            accountName: '',
            requestedAt: null
        })
        showFailed()
    }

    const setTimerCount = () => {
        if (pagaAgentDetails.requestedAt) {
            // Calculate the difference between otpRequestedAt and the current date in seconds
            const timeDiffInSeconds = Math.floor((new Date() - pagaAgentDetails.requestedAt) / 1000);

            // Calculate the seconds remaining on the timer, if any
            const secondsRemaining = defaultTimerCountInSeconds - timeDiffInSeconds;

            // Return either 0 or the remaining seconds, whichever is greater
            return secondsRemaining > 0 ? secondsRemaining : 0;
        }

        // If otpRequestedAt is false, return the default timer count in seconds
        return defaultTimerCountInSeconds;
    }

    return (
        <>
            {isLoading ? (
                <Loader fillParent={true} logoSize={'0'}/>
            ) : (
                <>
                    <SuccessModal show={pagaAgentStatus.status === PagaAgentStatus.SUCCESS} onHide={()=>{}}
                                  paymentDetails={{
                                      refNo: paymentParams.payment_reference,
                                      transactionType: 'Paga Agent',
                                      amount: paymentParams.amount,
                                      fee: pagaAgentFeeDetails.amount,
                                      tax: pagaAgentFeeDetails.taxes? pagaAgentFeeDetails.taxes.vat : 0.00,
                                      total: pagaAgentFeeDetails.total
                                  }}/>

                    { pagaAgentStatus.status === PagaAgentStatus.FAILED? <FailedAgentPayment/> : null}

                    { pagaAgentStatus.status === PagaAgentStatus.INITIALIZED?
                        <div className="box">
                            <div>
                                <PageHeading title="Pay with agent"
                                             description="To pay, provide this account number to a Paga agent"/>

                                { paymentError ?
                                    <FormError className={'mt-1'}>{paymentError}</FormError>
                                    : null
                                }

                                <div className="grey-container mt-1">
                                    <div className="darker-grey-container justify-content-center mb-2"
                                         style = {{padding: paymentError? '4px': '10px'}}>
                                        <h1 className="text-md grey-color">Amount</h1>
                                            <h1 className="text-xl fw-bold secondary-color text-spacing mb-2">
                                                {'₦' + pagaAgentFeeDetails.total}
                                                <CopySvg onClick={()=>{copyText(pagaAgentFeeDetails.total)}}
                                                         className="copy-button mx-1 mb-1"/>
                                            </h1>

                                        <h1 className="text-md grey-color">Account Number</h1>
                                        <h1 className="text-xl fw-bold secondary-color text-spacing mb-0">
                                            {pagaAgentDetails.accountNumber}
                                            <CopySvg onClick={()=>{copyText(pagaAgentDetails.accountNumber)}}
                                                     className="copy-button mx-1 mb-1"/>
                                        </h1>
                                    </div>

                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="text-sm grey-color">Bank Name</p>
                                        <p className="text-md fw-bold">{pagaAgentDetails.bankName}</p>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <p className="text-sm grey-color">Beneficiary Name</p>
                                        <p className="text-md fw-bold">{pagaAgentDetails.accountName}</p>
                                    </div>

                                </div>
                            </div>

                            <div>
                                <div className="grey-color text-sm mb-1">
                                    Please get in touch with your agent if the transaction isn't received in{" "}
                                    <CountdownTimer type="hours"
                                                    seconds={setTimerCount()}
                                                    onCompletion={onTimerCompletion}/>
                                </div>
                                <ButtonWidget className="modal-btn"
                                              onClick={checkPagaAgentPaymentStatus}
                                              loading={buttonLoading}
                                >
                                    {'I have transferred ₦' + pagaAgentFeeDetails.total}
                                </ButtonWidget>
                                <p className="page-text text-sm mt-1">
                                    {`Includes fee ₦${pagaAgentFeeDetails.amount}`}
                                </p>
                            </div>
                            <Toaster />
                        </div> : null}
                </>
            )}
        </>
    )
}

export default PagaAgentPayment
