import { Card } from "react-bootstrap"
import '../../styles/layout.css'
import CardPayment from "../../pages/payments/CardPayment"
import PaymentMenu from "./PaymentMenu"
import PagaPayment from "../../pages/payments/PagaPayment"
import Footer from "./Footer"
import Header from "./Header"
import OtherPayments from "../../pages/OtherPayments"
import {useCard} from "../../contexts/CardContext"
import {PaymentStatus, usePaymentStatus} from "../../contexts/PaymentStatusContext"
import {OTPContextProvider} from "../../contexts/OTPContext"
import Secure3DAuthentication from "../../pages/auth/Secure3DAuthentication"
import {PagaStatus, usePagaStatus} from "../../contexts/PagaStatusContext"
import {useMenuSelection} from "../../contexts/MenuSelectionContext"
import classNames from "classnames"
import {useState, useEffect} from "react"
import TransferPayment from "../../pages/payments/TransferPayment"
import {TransferStatusContextProvider} from "../../contexts/TransferStatusContext"
import PagaAgentPayment from "../../pages/payments/PagaAgentPayment"
import {PagaAgentContextProvider} from "../../contexts/PagaAgentContext"
import UssdPayment from "../../pages/payments/UssdPayment"
import Sidebar from "../elements/Sidebar"
import {getMerchantAndFeeDetails} from "../../services/MerchantService"
import {usePayment} from "../../contexts/PaymentContext"
import {useMerchant} from "../../contexts/MerchantContext"
import {PulseLoader} from "react-spinners"
import {FundingSourceType, getAllChannels, ValueType} from "../../constants/PaymentRequestConstants"
import {UssdContextProvider} from "../../contexts/UssdContext"
import {CARDARR} from "../../constants/CardConstants"
import SuccessModal from "../../pages/SuccessModal"
import {ErrorClass} from "../../constants/ErrorConstants";

const Checkout = () => {
    const [loading, setLoading] = useState(true)
    const [cardHeight, setCardHeight] = useState()
    const [checkoutHeight, setCheckoutHeight] = useState(window.innerHeight)
    const {paymentStatus} = usePaymentStatus()
    const {pagaStatus} = usePagaStatus()
    const {paymentParams} = usePayment()
    const {setMerchantDetails, setFeeDetails} = useMerchant()
    const {cardType} = useCard()
    const {selected, channels} = useMenuSelection()
    const classes = classNames(
        'checkout',
        'checkout-bg'
    )
    const {getFeeDetailsByChannel} = useMerchant()
    const cardFeeDetails = cardType? getFeeDetailsByChannel('CARD', cardType): getFeeDetailsByChannel('CARD')

    const cardStyle = {
        height: cardHeight,
    }

    const resetDimensions = ()=>{
        setCheckoutHeight(window.innerHeight)
        setCardHeight(resetCardHeight())
    }

    const resetCardHeight = (defaultHeight = "500px")=> {
        if (window.innerWidth <= 720) {
            // take full height on mobile
            return window.innerHeight + "px"
        } else {
            return defaultHeight
        }
    }

    useEffect(() => {
        window.addEventListener("resize", resetDimensions)
        resetDimensions()
        checkForRequiredParameters()

        //async function
        retrieveMerchantAndFeeDetails()
    }, [])

    const throwCheckoutError = (errorMessages) => {
        const messagesArray = Array.isArray(errorMessages) ? errorMessages : [errorMessages];
        const errorMessage = messagesArray.join('\n');

        alert(`Oops! Something went wrong:\n\n${errorMessage}\n\nPlease contact the merchant.`);

        // Send a message to the parent page to indicate checkout cancellation
        window.parent.postMessage('close-iframe', '*');
    };

    const checkForRequiredParameters = () => {
        // Get the search parameters from the current URL
        const urlParams = new URLSearchParams(window.location.search)

        // Array to store error messages
        const errors = []

        // Check if default parameters are set, if not add error message
        if (!urlParams.has('public_key')) {
            errors.push(ErrorClass.MISSING_PUBLIC_ID)
        }

        if (!urlParams.has('amount')) {
            errors.push(ErrorClass.MISSING_AMOUNT)
        }

        if (!urlParams.has('email')) {
            errors.push(ErrorClass.MISSING_EMAIL)
        }

        if (errors.length > 0) {
            throwCheckoutError(errors)
        }
    }

    const retrieveMerchantAndFeeDetails = async () =>{
        setLoading(true)
        // Make a request for transfer payment
        try {
            const res = await getMerchantAndFeeDetails(paymentParams)

            if (res.merchantResponse.statusCode === "000") {
                // Successful
                if (res.merchantResponse.statusMessage === "SUCCESS") {
                    // Transfer details retrieved
                    const merchantData = res.merchantResponse.merchant

                    setMerchantDetails({
                        accountId: merchantData.accountId,
                        accountNumber: merchantData.accountNumber,
                        name: merchantData.name,
                        email: merchantData.email,
                        logoUrl: merchantData.logoUrl,
                        displayName: merchantData.displayName,
                        phoneNumber: merchantData.phoneNumber
                    })
                }else{
                    //failed to get merchant details
                    throwCheckoutError(ErrorClass.NO_MERCHANT_DETAILS)
                }
            }else{
                //failed to get merchant details
                throwCheckoutError(ErrorClass.NO_MERCHANT_DETAILS)
            }

            if (res.feesResponse.statusCode === "000") {
                // Successful
                if (res.feesResponse.statusMessage === "SUCCESS") {
                    const feesData = res.feesResponse.fees
                    // Fee details retrieved
                    const feeDetails = getAllChannels().map((channel) => {
                        const channelAlternateName = FundingSourceType
                            .getMappedValue(channel, ValueType.CHANNEL, ValueType.ALTERNATE)

                        const feeKey = Object.keys(feesData).find((key) =>
                            key.toLowerCase().includes(channelAlternateName.toLowerCase())
                        )

                        if (feeKey) {
                            const fee = feesData[feeKey]
                            return {
                                channel,
                                ...fee
                            }
                        }
                        return null
                    })

                    const cardFeeDetails = CARDARR.map((cardType) => {
                        const cardChannelAlternateName = FundingSourceType
                            .getMappedValue("CARD", ValueType.CHANNEL, ValueType.ALTERNATE)
                        const feeKey = Object.keys(feesData).find((key) =>
                            key.toLowerCase().includes(cardChannelAlternateName.toLowerCase()) &&
                            key.toLowerCase().includes(cardType.toLowerCase())
                        )

                        if (feeKey) {
                            const fee = feesData[feeKey]
                            return {
                                channel: "CARD",
                                cardType,
                                ...fee
                            }
                        }
                        return null
                    })
                    setFeeDetails([...feeDetails,  ...cardFeeDetails])
                }else{
                    //failed to get fee details
                    throwCheckoutError(ErrorClass.NO_FEE_DETAILS)
                }
            }else{
                //failed to get fee details
                throwCheckoutError(ErrorClass.NO_FEE_DETAILS)
            }

        } catch (err) {
            throwCheckoutError(ErrorClass.NO_FEE_DETAILS)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes} style={{height: checkoutHeight}}>
            {loading? <PulseLoader size={10} speedMultiplier={0.7} color={'#f7931e'}/> :
            <>
                <Card className="rounded" style={cardStyle}>
                    <Card.Header>
                        <Header></Header>
                    </Card.Header>

                    <Card.Body>
                        <PagaAgentContextProvider>
                            <TransferStatusContextProvider>
                                <UssdContextProvider>
                                    <OTPContextProvider>
                                        {
                                            paymentStatus.status === PaymentStatus.SECURE3D ||
                                            pagaStatus.status === PagaStatus.SECURE3D
                                                ?
                                                <Secure3DAuthentication/> :
                                                <>
                                                    {channels.length > 1 &&
                                                        <PaymentMenu/>
                                                    }
                                                    <div className='payment-container'
                                                        style={
                                                            //remove padding from auth server
                                                            {padding:
                                                                ((selected === "paga") &&
                                                                (pagaStatus.status ===
                                                                    PagaStatus.AUTH_SERVER))?
                                                                '0': '16px'}}>
                                                            {selected === "card" && (
                                                                <CardPayment/>
                                                            )}
                                                            {selected === "paga" && <PagaPayment/>}
                                                            {selected === "ussd" && <UssdPayment/>}
                                                            {selected === "transfer" && <TransferPayment/>}
                                                            {selected === "agent" && <PagaAgentPayment/>}
                                                            {selected === "other" && <OtherPayments/>}
                                                    </div>
                                                </>
                                        }
                                    </OTPContextProvider>
                                </UssdContextProvider>
                            </TransferStatusContextProvider>
                        </PagaAgentContextProvider>
                    </Card.Body>
                </Card>
                <Footer/>
                <Sidebar/>
            </>}
            <SuccessModal show={paymentStatus.status === PaymentStatus.SUCCESS ||
                pagaStatus.status === PagaStatus.SUCCESS}
                          paymentDetails={{
                              refNo: paymentParams.payment_reference,
                              transactionType: pagaStatus.status === PagaStatus.SUCCESS? 'Card': 'Paga',
                              amount: paymentParams.amount,
                              fee: cardFeeDetails.amount,
                              tax: cardFeeDetails.taxes? cardFeeDetails.taxes.vat: null,
                              total: cardFeeDetails.total
                          }}/>
        </div>
    )
}

export default Checkout