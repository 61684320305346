import ReceiptItem from "../components/elements/ReceiptItem"
import {sanitizeUrl} from "../contexts/PaymentContext";
import Footer from "../components/layout/Footer";

const Receipt = () => {
    // Retrieve the data from params
    const urlParams = new URLSearchParams(window.location.search);
    const encodedObject = urlParams.get('data');
    const decodedData = JSON.parse(decodeURIComponent(encodedObject));
    const date = new Date();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const dateTime = new Intl.DateTimeFormat('en-US', options).format(date);
    const paymentDetails = decodedData.paymentDetails
    const merchantData = decodedData.merchantDetails
    const receiptDetails = Object.entries({
        'Payment Reference Number': paymentDetails.refNo,
        'Date': dateTime,
        'Transaction Type': paymentDetails.transactionType,
        ...paymentDetails.extraDetails,
        'Amount': `₦${paymentDetails.amount}`,
        'Fee': `₦${paymentDetails.fee}`,
        'Tax': !paymentDetails.tax? null : `₦${paymentDetails.tax}`,
        'Total': `₦${paymentDetails.total}`,
    })
    //const currentYear = new Date().getFullYear()

    return (
        <div className="checkout">
            <div className="col"></div>
            <div className="align-content-center receipt col-md-7 col-lg-5 my-5 p-4">
                {
                    merchantData.logoUrl?
                        <img src={sanitizeUrl(merchantData.logoUrl)} alt="logo" height={35}/>: null
                }

                <div className="darker-grey-container justify-content-center my-3 p-4 w-100">
                    <div className="text-md modal-text">{merchantData.name} received your payment of</div>
                    <div className="text-xxl text-center fw-bold secondary-color text-spacing mt-2">
                        {'₦' + paymentDetails.total}
                    </div>
                </div>

                <div className="text-lg fw-bold modal-text mb-2">Transaction Details</div>

                {receiptDetails.map(([key, value]) => (
                    <ReceiptItem key={key} title={key} value={value} highlight={key === 'Total'} />
                ))}

                <div className="w-100 py-4" style={{ borderBottom: '1px solid #e0e0e0' }}>
                    <div className="text-md fw-bold modal-text">{merchantData.name}</div>
                    <div className="text-md modal-text mt-1">{merchantData.phoneNumber}</div>
                    <div className="text-sm page-text grey-color mt-1">
                        If you have any questions regarding this transaction, please email{' '}
                        <span className="secondary-color text-decoration-underline">{merchantData.email}</span>
                    </div>
                </div>

                {/*<div className="text-sm page-text grey-color mt-4">{"© Paga " + currentYear}</div>*/}
                <Footer noDisplayMobile={false}/>
            </div>
            <div className="col"></div>
        </div>
    )
}

export default Receipt
