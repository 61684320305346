import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as MoneySvg} from "../../assets/svg/money.svg";
import '../../styles/layout.css';
import {useMenuSelection} from "../../contexts/MenuSelectionContext";

const propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    logo: PropTypes.elementType,
    show: PropTypes.bool,
    formatText: PropTypes.bool
}

const defaultProps = {
    text: 'Other',
    link: '/',
    logo: <MoneySvg/>,
    show: true,
    formatText: true
}

const OtherPaymentItem = ({text, link, logo, show, formatText}) => {
    const Logo = logo;

    //format label text to be first letter of each word uppercase
    if(formatText){
        text = text
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    const {changeSelected} = useMenuSelection()

    return (
        <>
            {
                show?
                    <div onClick={()=>{changeSelected(link)}}>
                        <div className="grid-item box-item">
                            <Logo/>
                            <div className="text mt-2">{text}</div>
                        </div>
                    </div>:
                    null
            }
        </>
    );
}

OtherPaymentItem.propTypes = propTypes;
OtherPaymentItem.defaultProps = defaultProps;

export default OtherPaymentItem;