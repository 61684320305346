import {ReactComponent as FooterLogo} from "../../assets/svg/logo/footer.svg";
import classNames from "classnames";

const Footer = ({noDisplayMobile = true}) => {
    const footerClassName = classNames(
        noDisplayMobile && 'no-display-mobile',
        !noDisplayMobile && 'd-flex',
        'footer'
    )

    return (
        <div className={footerClassName}>
            <div className="footer-text">Secured by</div>
            <FooterLogo className="footer-logo"></FooterLogo>
        </div>
    )
}

export default Footer