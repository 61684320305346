import React from 'react';
import MaskedInput from 'react-text-mask';
import classNames from "classnames";

const MaskedInputField = React.forwardRef((props, ref) => {
    const formInputClasses = (name) => classNames(
        props.className,
        'input-field',
        props.error && `error`,
        //isFocused && 'selected',
        name === 'cardNumber' && 'right-padding'
    );

    const handleOnFocus = ()=>{
    }

    return (
        <div className={formInputClasses(props.name)}>
            <div className="text-start text-sm input-label">{props.label}</div>
            <MaskedInput
                {...props}
                className="masked-input"
                placeholderChar={'\u2000'}
                guide={false}
                onFocus={handleOnFocus}
                ref={ref}
            />
        </div>

    );
})

export default MaskedInputField;