export class ValueType{
    static CHANNEL = "channelName";
    static ALTERNATE = "alternateName";
    static DEFAULT = "type";
}

export class FundingSourceType {
    static DEBIT_CARD = "CARD_DEBIT";
    static TRANSFER = "BANK_ACCOUNT_TRANSFER";
    static USSD = "BANK_USSD";
    static AGENT = "AGENT_OTC";
    static APP = "IN_APP_PUSH";
    static PAGA = "PAGA_DEBIT";
    static PAGA_CARD = "PAGA_CARD";
    static PAGA_BANK = "PAGA_BANK";
    static BANK_ACCOUNT = "BANK_ACCOUNT_DEBIT";

    static mappings = [
        { type: FundingSourceType.DEBIT_CARD, alternateName: "CardDebit", channelName: "CARD" },
        { type: FundingSourceType.TRANSFER, alternateName: "BankAccountTransfer", channelName: "TRANSFER" },
        { type: FundingSourceType.USSD, alternateName: "BankUSSD", channelName: "USSD" },
        { type: FundingSourceType.AGENT, alternateName: "AgentOTC", channelName: "AGENT" },
        { type: FundingSourceType.APP, alternateName: "InAppPush", channelName: "APP" },
        { type: FundingSourceType.PAGA, alternateName: "PagaAccountDebit", channelName: "PAGA" },
        { type: FundingSourceType.BANK_ACCOUNT, alternateName: "BankAccountDebit", channelName: "BANK" },
    ];

    static getMappedValue(input, inputType = ValueType.CHANNEL, outputType = ValueType.ALTERNATE) {
        for (const mapping of FundingSourceType.mappings) {
            if (input === mapping[inputType]) {
                return mapping[outputType];
            }
        }
        return null;
    }
}

export function getAllChannels() {
    const channelNames = [];
    for (const mapping of FundingSourceType.mappings) {
        channelNames.push(mapping.channelName);
    }
    return channelNames;
}