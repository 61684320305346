import {createContext, useContext, useState} from "react"
import {TransferStatus} from "./TransferStatusContext";

export class PagaAgentStatus {
    static UNINITIALIZED = "uninitialized";
    static INITIALIZED = "initialized";
    static FAILED = "failed";
    static SUCCESS = "success"

    constructor(type) {
        this.type = type;
    }
}

export const PagaAgentContext = createContext(null)

export const PagaAgentContextProvider = ({ children }) => {
    const [pagaAgentStatus, setPagaAgentStatus] = useState({
        status: PagaAgentStatus.UNINITIALIZED,
        message: ''
    });

    const [pagaAgentDetails, setPagaAgentDetails] = useState({
        accountNumber: '',
        accountName: '',
        bankName: '',
        requestedAt: null
    })

    const loadPagaAgentDetails = () => {
        setPagaAgentStatus({
            status: TransferStatus.UNINITIALIZED,
            message: ''
        })
    }

    const showPagaAgentDetails = () => {
        setPagaAgentStatus({
            status: PagaAgentStatus.INITIALIZED,
            message: ''
        })
    }

    const showFailed = (message) => {
        setPagaAgentStatus({
            status: PagaAgentStatus.FAILED,
            message: message
        })
    }

    const showSuccess = () => {
        setPagaAgentStatus({
            status: PagaAgentStatus.SUCCESS,
            message: ''
        })
    }

    const setErrorMessage = (message) => {
        setPagaAgentStatus({
            status: pagaAgentStatus.status,
            message: message
        })
    }

    const value = {pagaAgentStatus, setErrorMessage, showFailed, showSuccess, loadPagaAgentDetails, showPagaAgentDetails,
        pagaAgentDetails, setPagaAgentDetails}

    return(
        <PagaAgentContext.Provider value={value}> {children} </PagaAgentContext.Provider>
    );
}

export default PagaAgentContext;

export const usePagaAgentStatus = ()=>{
    return useContext(PagaAgentContext);
}