import {ReactComponent as SmallLogoSvg} from "../../assets/svg/logo/small2.svg";
import {useMenuSelection} from "../../contexts/MenuSelectionContext";
import PaymentMenu from "../layout/PaymentMenu";
import {useEffect, useRef} from "react";

function SideBar() {
    const {mobileMenuOpen, setMobileMenuOpen} = useMenuSelection()
    const sideBarRef = useRef(null);
    useClickOutsideListener(sideBarRef, ()=>setMobileMenuOpen(false));

    return (
        <>
            {mobileMenuOpen?
                <div className="sidebar only-display-mobile" ref={sideBarRef}>
                    <PaymentMenu displayMobile={true} itemPadding={'20px'} logo={SmallLogoSvg}/>
                </div>:
                null
            }
        </>
    );
}

function useClickOutsideListener(ref, onClick = ()=>{}) {
    useEffect(() => {
        /**
         * if clicked on outside of sidebar
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClick()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default SideBar;