import {createContext, useContext, useState} from "react"
import DOMPurify from 'dompurify'
import {generateId} from "../services/BaseService"

export const PaymentContext = createContext(null)

export const PaymentContextProvider = ({ children }) => {
    const defaultPaymentParams = {
        charge_url: '',
        redirect_url_method: 'GET',
        public_key: getDefaultMerchantId(),
        amount: window.env.DEMO_PAYMENT_AMOUNT,
        currency: 'NGN',
        payment_reference: generateId(),
        account_number: '',
        product_description: '',
        phone_number: '',
        email: '',
        product_codes: '',
        display_name: '',
        display_tagline: '',
        button_label: 'Pay',
        width: '',
        callback_url: '',
        funding_sources: 'CARD,PAGA,TRANSFER,AGENT,USSD',
        card_scheme: 'mastercard,visa,verve'
    }
    const searchParams = new URLSearchParams(window.location.search)
    const paymentParams = {
        charge_url: searchParams.get('charge_url') ? searchParams.get('charge_url') : defaultPaymentParams.charge_url,
        redirect_url_method: searchParams.get('redirect_url_method') ? searchParams.get('redirect_url_method') : defaultPaymentParams.redirect_url_method,
        public_key: searchParams.get('public_key') ? searchParams.get('public_key') : defaultPaymentParams.public_key,
        amount: searchParams.get('amount') ? searchParams.get('amount') : defaultPaymentParams.amount,
        currency: searchParams.get('currency') ? searchParams.get('currency') : defaultPaymentParams.currency,
        payment_reference: searchParams.get('payment_reference') ? searchParams.get('payment_reference') : defaultPaymentParams.payment_reference,
        account_number: searchParams.get('account_number') ? searchParams.get('account_number') : defaultPaymentParams.account_number,
        product_description: searchParams.get('product_description') ? searchParams.get('product_description') : defaultPaymentParams.product_description,
        phone_number: searchParams.get('phone_number') ? searchParams.get('phone_number') : defaultPaymentParams.phone_number,
        email: searchParams.get('email') ? searchParams.get('email') : defaultPaymentParams.email,
        product_codes: searchParams.get('product_codes') ? searchParams.get('product_codes') : defaultPaymentParams.product_codes,
        display_name: searchParams.get('display_name') ? searchParams.get('display_name') : defaultPaymentParams.display_name,
        display_tagline: searchParams.get('display_tagline') ? searchParams.get('display_tagline') : defaultPaymentParams.display_tagline,
        button_label: searchParams.get('button_label') ? searchParams.get('button_label') : defaultPaymentParams.button_label,
        width: searchParams.get('width') ? searchParams.get('width') : defaultPaymentParams.width,
        callback_url: searchParams.get('callback_url') ? searchParams.get('callback_url') : defaultPaymentParams.callback_url,
        funding_sources: searchParams.get('funding_sources')? searchParams.get('funding_sources') :
            defaultPaymentParams.funding_sources ,
        card_scheme: searchParams.get('card_scheme')? searchParams.get('card_scheme') :
            defaultPaymentParams.card_scheme
    }

    //sanitize urls passed
    // paymentParams.merchant_logo_url = sanitizeUrl(paymentParams.merchant_logo_url)
    paymentParams.charge_url = sanitizeUrl(paymentParams.charge_url)
    paymentParams.callback_url = sanitizeUrl(paymentParams.callback_url)

    //convert amount to decimal
    paymentParams.amount = convertToDecimal(paymentParams.amount)

    //convert payment channels list to uppercase
    paymentParams.funding_sources = paymentParams.funding_sources.toUpperCase()
    paymentParams.card_scheme = paymentParams.card_scheme.toUpperCase()

    const [checkoutUrl, setCheckoutUrl] = useState({
        hostName: null,
        baseUrl: null
    });

    const value = {paymentParams, defaultPaymentParams, checkoutUrl, setCheckoutUrl}

    return(
        <PaymentContext.Provider value={value}> {children} </PaymentContext.Provider>
    )
}

export default PaymentContext

export const usePayment = ()=>{
    return useContext(PaymentContext)
}

//check if url passed are valid
function validateUrl(url) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/
    return regex.test(url)
}

export function sanitizeUrl(url) {
    let sanitizedLink

    // Check for a valid protocol
    if (!validateUrl(url)) {
        return null
    }

    // Remove any whitespace
    url = url.trim()

    // Remove any potential injection attacks
    url = url.replace(/<script.*?>.*?<\/script>/gi, '')
    url = url.replace(/javascript:/gi, '')

    // Encode any potentially harmful characters
    url = encodeURI(url)

    // Check for a valid hostname
    // eslint-disable-next-line
    let hostname = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im)
    if (hostname === null) {
        return null
    }

    // Check for a valid path
    // eslint-disable-next-line
    let path = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?[^:\/\n]+(\/[^?#]+)/im)
    if (path === null) {
        sanitizedLink = `${url.substring(0, url.indexOf(hostname[1]) + hostname[1].length)}`
    }else{
        // Combine the hostname and path to form the sanitized URL
        sanitizedLink = `${url.substring(0, url.indexOf(hostname[1]) + hostname[1].length)}${path[1]}`
    }

    return DOMPurify.sanitize(sanitizedLink)
}

function convertToDecimal(str) {
    const number = parseFloat(str)
    if (isNaN(number)) {
        return null // handle invalid input
    }
    return number.toFixed(2)
}

export function getDefaultMerchantId(){
    //uses test merchant id in all envs except live
    return window.env.DEMO_MERCHANT_ID
}

export function getCallbackUrlWithParameters(paymentParams, status = 'success') {
    const redirectUrl = paymentParams.charge_url;
    let statusCode
    switch (status){
        case 'success':
            statusCode = 0
            break
        case 'pending':
            statusCode = 2
            break
        case 'failed':
            statusCode = -1
            break
        default:
            statusCode = 0
            break
    }

    //redirect with search parameters
    const redirectParams = {
        'charge_reference': paymentParams.payment_reference,
        'status_message': status,
        'status_code': statusCode
    };

    const searchParams = new URLSearchParams(redirectParams).toString();
    return `${redirectUrl}?${searchParams}`;
}
