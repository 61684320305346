import ButtonWidget from "../../components/elements/ButtonWidget"
import OTPInputField from "../../components/elements/OTPInputField"
import {useOtp} from "../../contexts/OTPContext"
import CountdownTimer from "../../components/elements/CountdownTimer"
import {resendOTP, verifyOTP} from "../../services/CardService"
import {useState} from "react"
import PageHeading from "../../components/elements/PageHeading"
import {usePayment} from "../../contexts/PaymentContext"
import PropTypes from "prop-types";
import FormError from "../../components/elements/FormError";
import {ErrorClass} from "../../constants/ErrorConstants";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";
import {
    resendLinkedBankOTP,
    resendLinkedCardOTP,
    verifyLinkedBankOTP,
    verifyLinkedCardOTP
} from "../../services/PagaService";
import {usePagaStatus} from "../../contexts/PagaStatusContext";

const propTypes = {
    title: PropTypes.string,
    onBack: PropTypes.func,
    onSuccess: PropTypes.func,
    message: PropTypes.string,
    fundingSourceType: PropTypes.string,
    otpLength: PropTypes.number
}

const defaultProps = {
    title: 'OTP Authentication',
    onBack: ()=>{},
    onSuccess: ()=>{},
    message: '',
    fundingSourceType: FundingSourceType.DEBIT_CARD,
    otpLength: 5
}

const OTPAuthentication = ({
    title,
    onBack,
    onSuccess,
    message,
    fundingSourceType,
    otpLength
   })=>{
    const {paymentParams} = usePayment()
    const [allowOTPResend, setAllowOTPResend] = useState(false)
    const [disableOTPButton, setDisableOTPButton] = useState(false)
    const [otp, setOtp] = useState('')
    const {otpRequestedAt, setOtpRequestedAt} = useOtp()
    const {pagaOtpRequestedAt, setPagaOtpRequestedAt} = usePagaStatus()
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [otpLoading, setOtpLoading] = useState(false)
    const [otpError, setOtpError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const defaultTimerCountInSeconds = 90

    const handleResendOTP = async ()=>{
        setOtpLoading(true)
        setDisableOTPButton(true)
        try{
            let res
            switch (fundingSourceType){
                case FundingSourceType.PAGA_CARD:
                    res = await resendLinkedCardOTP(paymentParams)
                    break
                case FundingSourceType.PAGA_BANK:
                    res = await resendLinkedBankOTP(paymentParams)
                    break
                default:
                    res = await resendOTP(paymentParams)
                    break
            }

            if(res.statusCode === '000'){
                //otp has been sent
                resetTimer()
            }else{
                //failed to resend otp
            }
        }catch (err){
            //catch otp not resent
            //console.log(err)
        }finally {
            setOtpLoading(false)
            setDisableOTPButton(false)
        }
    }

    const resetTimer = () => {
        setDisableOTPButton(false)
        setAllowOTPResend(false)
        switch (fundingSourceType){
            case FundingSourceType.PAGA_BANK:
            case FundingSourceType.PAGA_CARD:
                setPagaOtpRequestedAt(new Date())
                break

            default:
                setOtpRequestedAt(new Date())
                break
        }
    }

    const setTimerCount = () => {
        let requestedAt
        switch (fundingSourceType){
            case FundingSourceType.PAGA_BANK:
            case FundingSourceType.PAGA_CARD:
                requestedAt = pagaOtpRequestedAt
                break

            default:
                requestedAt = otpRequestedAt
                break
        }
        if (requestedAt) {
            // Calculate the difference between requestedAt and the current date in seconds
            const timeDiffInSeconds = Math.floor((new Date() - requestedAt) / 1000)

            // Calculate the seconds remaining on the timer, if any
            const secondsRemaining = defaultTimerCountInSeconds - timeDiffInSeconds

            // Return either 0 or the remaining seconds, whichever is greater
            return secondsRemaining > 0 ? secondsRemaining : 0
        }

        // If otpRequestedAt is false, return the default timer count in seconds
        return defaultTimerCountInSeconds
    }

    const handleVerifyOTP = async ()=>{
        setVerifyLoading(true)
        setErrorMessage(null)
        // Make a request to verify otp
        try {
            let res
            switch (fundingSourceType){
                case FundingSourceType.PAGA_CARD:
                    res = await verifyLinkedCardOTP(paymentParams, otp)
                    break
                case FundingSourceType.PAGA_BANK:
                    res = await verifyLinkedBankOTP(paymentParams, otp)
                    break
                default:
                    res = await verifyOTP(paymentParams, otp)
                    break
            }

            switch (res.status) {
                case "SUCCESSFUL":
                    onSuccess()
                    break
                case "FAILED":
                    onBack(res.statusMessage)
                    break
                default:
                    break
            }
        } catch (err){
            setErrorMessage(ErrorClass.FAILED_OTP_VERIFICATION)
        } finally {
            setVerifyLoading(false)
            setOtp('')
        }
    }

    const handleTimerCompletion = ()=>{
        setAllowOTPResend(true)
    }

    const handleOTPChange = (otp) => {
        setOtpError(false)
        setOtp(otp)
    }

    return (
        <div className="box">
            <div>
                <PageHeading className="mb-2" title=" " description=""
                    hasBackButton={true} onBackButtonClicked={()=>onBack()}/>
                <h5 className="modal-heading-text">{title}</h5>
                <h6 className="modal-text mt-3 text-md">
                    {message}
                </h6>

                { errorMessage ?
                    <FormError>{errorMessage}</FormError>: null }

                <OTPInputField className='mt-3' value={otp} onChange={handleOTPChange}
                               hasError={otpError} noOfInputs={otpLength}/>

                {
                    !allowOTPResend?
                        <div className="modal-text mt-4">
                            Resend OTP in <CountdownTimer onCompletion={handleTimerCompletion} seconds={setTimerCount()}/>
                        </div>:
                        <ButtonWidget onClick={handleResendOTP}
                                className="mt-2 modal-text bg-transparent py-0 border-0"
                                disabled={disableOTPButton}
                                textColor={'#5c91ee'}
                                loading={otpLoading}>
                            Resend OTP Code
                        </ButtonWidget>
                }
            </div>

            <ButtonWidget className="modal-btn" disabled={otp.length < otpLength}
                          onClick={handleVerifyOTP} loading={verifyLoading}>Verify</ButtonWidget>
        </div>
    )
}

OTPAuthentication.propTypes = propTypes
OTPAuthentication.defaultProps = defaultProps

export default OTPAuthentication
