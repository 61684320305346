import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import {ReactComponent as ViewOffSvg} from "../../assets/svg/viewoff.svg";
import {ReactComponent as ViewSvg} from "../../assets/svg/view.svg";

const propTypes = {
  onToggle: PropTypes.func,
  showValue: PropTypes.bool
}

const defaultProps = {
  onToggle: ()=>{},
  showValue: false
}

const EyeToggle = ({
    onToggle,
    showValue,
    className
}) => {
  const classes = classNames(
      className,
      "border-0",
      "bg-transparent",
  )

  const handleClick = ()=>{
    onToggle()
  }

  return (
      <button className={classes}
              onClick={handleClick}
      >
        {showValue?
            <ViewOffSvg/>:
            <ViewSvg/>
        }
      </button>
  );
}

EyeToggle.propTypes = propTypes;
EyeToggle.defaultProps = defaultProps;

export default EyeToggle;