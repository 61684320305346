import {createContext, useContext, useState} from "react"

export class PaymentStatus {
    static START = "start";
    static SUCCESS = "success";
    static FAILED = "failed";
    static OTP = "OTP";
    static PIN = "PIN";
    static SECURE3D = "3DS"

    constructor(type) {
        this.type = type;
    }
}

export const PaymentStatusContext = createContext(null)

export const PaymentStatusContextProvider = ({ children }) => {
    const [paymentStatus, setPaymentStatus] = useState({
        status: PaymentStatus.START,
        message: ''
    });

    const showSuccessModal = () => {
        setPaymentStatus({
            status: PaymentStatus.SUCCESS,
            message: ''
        })
    }

    const showFailedModal = (errorMessage) => {
        setPaymentStatus({
            status: PaymentStatus.START,
            message: errorMessage
        })
    }

    const showOTPAuth = (message) => {
        setPaymentStatus({
            status: PaymentStatus.OTP,
            message: message
        })
    }

    const showPINAuth = () => {
        setPaymentStatus({
            status: PaymentStatus.PIN,
            message: ''
        })
    }

    const showSecure3DAuth = () => {
        setPaymentStatus({
            status: PaymentStatus.SECURE3D,
            message: ''
        })
    }

    const closeModal = () => {
        setPaymentStatus(prevStatus => ({
            ...prevStatus,
            message: prevStatus.status === PaymentStatus.OTP? prevStatus.message : ''
        }))
    }

    const value = {paymentStatus, setPaymentStatus, showSuccessModal, showPINAuth,
        showFailedModal, showOTPAuth, showSecure3DAuth, closeModal}

    return(
        <PaymentStatusContext.Provider value={value}> {children} </PaymentStatusContext.Provider>
    );
}

export default PaymentStatusContext;

export const usePaymentStatus = ()=>{
    return useContext(PaymentStatusContext);
}