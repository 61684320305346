import {ReactComponent as MainLogo} from '../../assets/svg/logo/main.svg'
import PageHeading from "../../components/elements/PageHeading";
import MaskedInputField from "../../components/elements/MaskedInputField";
import ButtonWidget from "../../components/elements/ButtonWidget";
import {PagaStatus, usePagaStatus} from "../../contexts/PagaStatusContext";
import Authorization from "../auth/Authorization";
import PayWithApp from "./PayWithApp";
import PayWithPaga from "./PayWithPaga";
import PasswordAuthentication from "../auth/PasswordAuthentication";
import SuccessModal from "../SuccessModal";
import {useEffect, useState} from "react";
import FormError from "../../components/elements/FormError";
import {ErrorClass} from "../../constants/ErrorConstants";
import {mergeObjectsWithPriority, requestPayment} from "../../services/PaymentService";
import {FundingSourceType} from "../../constants/PaymentRequestConstants";
import {usePayment} from "../../contexts/PaymentContext";
import {useMerchant} from "../../contexts/MerchantContext";
import FailedAppPayment from "./failed/FailedAppPayment";
import OTPAuthentication from "../auth/OTPAuthentication";
import PinAuthentication from "../auth/PinAuthentication";

const PagaPayment = () => {
    const {pagaStatus, showPayWithApp, setAppPaymentRequestedAt, selectedFundingSource, showSuccessfulPayment,
        loginInfo, setLoginInfo, setErrorMessage, showAuth, showPayWithPaga, appUser} = usePagaStatus()
    const [appButtonLoading, setAppButtonLoading] = useState(false)
    const {paymentParams} = usePayment()
    const {getFeeDetailsByChannel} = useMerchant()
    const pagaFeeDetails = getFeeDetailsByChannel('PAGA')
    let errorMessage = ''

    useEffect(() => {
        if(paymentParams.email) setLoginInfo(paymentParams.email)
        return () => {
            // reset error on unmounted
            setErrorMessage('')
        };
    }, []);

    const requestAppPayment = async () =>{
        setAppButtonLoading(true)
        setErrorMessage(null)
        const loginType = validateStringType(loginInfo)

        const userParameters = {
            "email": loginType === 'email'? loginInfo : null,
            "phone_number": loginType === 'phone'? loginInfo: null,
            "username": loginType === 'username'? loginInfo: null,
        }

        // Make a request to request charge
        try {
            const res = await requestPayment(
                mergeObjectsWithPriority(userParameters, paymentParams)
                , FundingSourceType.APP, [], loginInfo)

            if (res.status === "PENDING") {
                // App payment requested
                setAppPaymentRequestedAt(new Date())
                showPayWithApp()
            }else{
                setErrorMessage(res.statusMessage? res.statusMessage : ErrorClass.FAILED_INITIALIZATION)
            }
        } catch (err) {
            setErrorMessage(ErrorClass.FAILED_INITIALIZATION)
        } finally {
            setAppButtonLoading(false)
        }
    }

    const handleInputData = (e)=>{
        const value = e.target.value

        //reset user validated on change
        setErrorMessage('')

        setLoginInfo(value)
    }

    const handleBlur = () => {
        //show error message
        setErrorMessage(errorMessage)
    }

    const validateStringType = (str)=> {
        // Regular expressions to check for valid email, username, and phone number formats
        const emailRegex = /\S+@\S+\.\S+/;
        const usernameRegex = /^[a-zA-Z0-9_]{4,}$/;

        function isNumber(str) {
            return !isNaN(parseFloat(str)) && isFinite(str);
        }

        if (emailRegex.test(str)) {
            return 'email';
        } else if (isNumber(str)) {
            return 'phone';
        } else if (usernameRegex.test(str)) {
            return 'username';
        } else {
            return 'username';
        }
    }

    const disableButton = () => {
        return loginInfo.length < 3
    }

    const disablePayWithApp = ()=>{
        return appUser && (appUser !== loginInfo)
    }

    return (
        <>
            { pagaStatus.status === PagaStatus.OTP ?
                <OTPAuthentication message={pagaStatus.message}
                                   fundingSourceType={selectedFundingSource}
                                   onBack={(message)=>{
                                        showPayWithPaga(undefined, message)
                                   }}
                                   onSuccess={()=>{
                                       showSuccessfulPayment()
                                   }}
                                />
                : null }

            { pagaStatus.status === PagaStatus.PIN ?
                <PinAuthentication/> : null }

            { pagaStatus.status === PagaStatus.AUTH_SERVER ?
                <Authorization/> : null }

            { pagaStatus.status === PagaStatus.APP ?
                <PayWithApp/> : null }

            { pagaStatus.status === PagaStatus.AUTHENTICATED ?
                <PayWithPaga/> : null }

            { pagaStatus.status === PagaStatus.PASSWORD_AUTH ?
                <PasswordAuthentication/> : null }

            { pagaStatus.status === PagaStatus.FAILED_APP_PAYMENT ?
                <FailedAppPayment/> : null }

            { pagaStatus.status === PagaStatus.UNAUTHENTICATED?
                <div className="box">
                    <div>
                        <PageHeading className="mb-3" title="" description="Pay with your Paga account">
                            <MainLogo className="mb-2"/>
                        </PageHeading>

                        { pagaStatus.message ?
                            <FormError className="mb-2">{pagaStatus.message}</FormError>: null}

                        <MaskedInputField
                            mask={false}
                            label="Paga Account ID"
                            guide={false}
                            placeholderChar={"\u2000"}
                            placeholder="Email, Username or Phone number"
                            name="loginInfo"
                            required
                            value={loginInfo}
                            onChange={handleInputData}
                            onBlur={handleBlur}
                        />

                        <div className="darker-grey-container mt-3 p-2">
                            <div className="d-flex justify-content-between m-1">
                                <p className="text-md grey-color">
                                    Pay a total of:
                                </p>
                                <div className="d-flex flex-column">
                                    <p className="text-xl justify-content-end fw-semibold">{'₦' + pagaFeeDetails.total}</p>
                                    <p className="text-sm grey-color justify-content-end">
                                        {'Includes fee ₦' + pagaFeeDetails.amount}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="d-flex justify-content-between">
                            <ButtonWidget className="modal-btn" color={`#f37244`}
                                  onClick={()=> {
                                      showAuth()
                                  }} disabled={disableButton() || appButtonLoading}>
                                Login to pay
                            </ButtonWidget>
                            <div className="mx-2"></div>
                            <ButtonWidget className="modal-btn"
                                          disabled={disableButton() || disablePayWithApp()}
                                  onClick={()=> {
                                      setErrorMessage('')
                                      setAppButtonLoading(true)
                                      requestAppPayment().then(()=>{setAppButtonLoading(false)})
                                  }}
                                  loading={appButtonLoading}>Pay with app</ButtonWidget>
                        </div>
                    </div>
                </div>
            : null}

            <SuccessModal show={pagaStatus.status === PagaStatus.SUCCESS} onHide={()=>{}}
                          paymentDetails={{
                              refNo: paymentParams.payment_reference,
                              transactionType: 'Paga',
                              amount: paymentParams.amount,
                              fee: pagaFeeDetails.amount,
                              tax: pagaFeeDetails.taxes? pagaFeeDetails.taxes.vat: null,
                              total: pagaFeeDetails.total,
                              extraDetails: {
                                  'Email': loginInfo
                              },
                          }}/>
        </>
    )
}

export default PagaPayment