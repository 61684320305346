import PageHeading from "../../components/elements/PageHeading"
import {ReactComponent as CopySvg} from "../../assets/svg/copy.svg"
import {ReactComponent as RefreshSvg} from "../../assets/svg/refresh.svg"
import {ReactComponent as DialSvg} from "../../assets/svg/dial.svg"
import CountdownTimer from "../../components/elements/CountdownTimer"
import ButtonWidget from "../../components/elements/ButtonWidget"
import toast, {Toaster} from "react-hot-toast"
import {usePayment} from "../../contexts/PaymentContext"
import {useState, useEffect} from "react"
import SelectUssdBank from "../SelectUssdBank"
import {UssdStatus, useUssdStatus} from "../../contexts/UssdContext"
import copy from "copy-to-clipboard"
import {checkPaymentStatus, getBanks, handlePaymentStatus} from "../../services/PaymentService"
import {FundingSourceType} from "../../constants/PaymentRequestConstants"
import {ErrorClass} from "../../constants/ErrorConstants"
import {useMerchant} from "../../contexts/MerchantContext"
import Loader from "../../components/elements/Loader"
import SuccessModal from "../SuccessModal"
import FailedUssd from "./failed/FailedUssd"
import FormError from "../../components/elements/FormError"

const UssdPayment = () => {
    const defaultTimerCountInSeconds = 5 * 60
    const pollingInitialInterval = window.env.POLLING_DURATION_SECS * 1000
    const [buttonLoading, setButtonLoading] = useState(false)
    const {paymentParams} = usePayment()
    const [isLoading, setIsLoading] = useState(false)
    const {ussdStatus, showFailed, showSuccess, ussdDetails, showSelectBank, setBanks, banks,
        ussdRequestedAt, setUssdDetails} = useUssdStatus()
    const [paymentError, setPaymentError] = useState(null)
    const {getFeeDetailsByChannel} = useMerchant()
    const ussdFeeDetails = getFeeDetailsByChannel('USSD')
    let copiedToast
    let timer

    useEffect(()=>{
        if(!banks && ussdStatus.status === UssdStatus.UNINITIALIZED){
            retrieveBanks()
        }else if(ussdStatus.status === UssdStatus.INITIALIZED){
            timer = setTimeout(startPolling, pollingInitialInterval)
        }
        
        setPaymentError('')

        return ()=>{
            clearTimeout(timer)
            if(copiedToast){
                toast.dismiss(copiedToast)
            }
        }
    }, [ussdStatus, ussdDetails])

    const startPolling = () => {
        checkUssdPaymentStatus(false).then(()=>{
            // Schedule the next poll after the interval duration
            timer = setTimeout(startPolling, pollingInitialInterval)
        })
    }

    const copyText = (text) => {
        copy(text)
        if(copiedToast){
            toast.dismiss(copiedToast)
        }
        copiedToast = toast('Copied to clipboard', {
            position: 'bottom-right',
            duration: 2000,
            className: 'page-text',
        })
    }

    const checkUssdPaymentStatus = async (showError = true)=>{
        if(showError) setButtonLoading(true)

        try {
            checkPaymentStatus(paymentParams, FundingSourceType.USSD)
                .then(response => response.json())
                .then((res) =>{
                    handlePaymentStatus(res, setPaymentError, showSuccess, showError)
                    if(showError) setButtonLoading(false)
                })
                .catch(() => {
                    if(showError){
                        setPaymentError(ErrorClass.FAILED_INITIALIZATION)
                        setButtonLoading(false)
                    }
                })
        } catch (err) {
            setPaymentError(ErrorClass.FAILED_INITIALIZATION)
            setButtonLoading(false)
        }
    }

    const onTimerCompletion = ()=>{
        setUssdDetails({
            ussdShortCode: '',
            paymentReference: '',
            requestedAt: null
        })
        showFailed()
    }

    const retrieveBanks = async () =>{
        setIsLoading(true)
        try {
            const res = await getBanks()
            setBanks(res.financialInstitutions)
        } catch (err) {
            //console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const setTimerCount = () => {
        if (ussdRequestedAt) {
            // Calculate the difference between otpRequestedAt and the current date in seconds
            const timeDiffInSeconds = Math.floor((new Date() - ussdRequestedAt) / 1000)

            // Calculate the seconds remaining on the timer, if any
            const secondsRemaining = defaultTimerCountInSeconds - timeDiffInSeconds

            // Return either 0 or the remaining seconds, whichever is greater
            return secondsRemaining > 0 ? secondsRemaining : 0
        }

        // If otpRequestedAt is false, return the default timer count in seconds
        return defaultTimerCountInSeconds
    }

    return (
        <>
            {isLoading ? (
                <Loader fillParent={true} logoSize={'0'}/>
            ) : (
                <>
                    <SuccessModal
                        show={ussdStatus.status === UssdStatus.SUCCESS}
                        onHide={() => {}}
                        paymentDetails={{
                            refNo: paymentParams.payment_reference,
                            transactionType: 'Ussd',
                            amount: paymentParams.amount,
                            fee: ussdFeeDetails.amount,
                            tax: ussdFeeDetails.taxes? ussdFeeDetails.taxes.vat : 0.00,
                            total: ussdFeeDetails.total
                            // extraDetails: {
                            //     'Payer Bank Name': 'GT Bank',
                            //     'Payer Account Name': 'John Doe',
                            //     'Payer Account Number': '0012344432'
                            // },
                        }}
                    />
                    {ussdStatus.status === UssdStatus.FAILED ? (
                        <FailedUssd/>
                    ) : null}
                    {ussdStatus.status === UssdStatus.UNINITIALIZED ? (
                        <SelectUssdBank/>
                    ) : null}
                    {ussdStatus.status === UssdStatus.INITIALIZED ? (
                        <div className="box">
                            <div>
                                <PageHeading
                                    title="Pay with USSD"
                                    description="Dial the code below on your mobile phone to complete the payment"
                                />

                                { paymentError ?
                                    <FormError className={'mt-1'}>{paymentError}</FormError>
                                    : null
                                }

                                <div className="grey-container mt-1">
                                    <div className="darker-grey-container justify-content-center mb-2"
                                         style = {{padding: paymentError? '4px': '10px'}}>

                                        <h1 className="text-md grey-color">
                                            USSD Code
                                        </h1>
                                        <h1 className="text-xl fw-bold secondary-color
                                        text-spacing mb-0 align-items-end">
                                            <DialSvg className="mb-1"/>
                                            {ussdDetails.ussdShortCode}
                                            <CopySvg
                                                onClick={() => {
                                                    copyText(ussdDetails.ussdShortCode)
                                                }}
                                                className="copy-button mx-1 mb-1"
                                            />
                                        </h1>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <p className="text-sm grey-color">
                                            Amount
                                        </p>
                                        <p className="text-md fw-bold">{'₦' + ussdFeeDetails.total}</p>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center mt-4'>
                                    <div className='d-flex align-items-end clickable' onClick={showSelectBank}>
                                        <RefreshSvg height={'22px'}/>
                                        <div className='mx-2 secondary-color'>
                                            Choose another bank
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="grey-color text-sm mb-1">
                                    Please get in touch with your bank if the transaction isn't received in{" "}
                                    <CountdownTimer
                                        seconds={setTimerCount()}
                                        onCompletion={onTimerCompletion}
                                    />
                                </div>

                                <ButtonWidget
                                    className="modal-btn"
                                    onClick={checkUssdPaymentStatus}
                                    loading={buttonLoading}
                                >
                                    {'I have completed this payment'}
                                </ButtonWidget>
                            </div>
                            <Toaster />
                        </div>
                    ) : null}
                </>
            )}
        </>
    )
}

export default UssdPayment