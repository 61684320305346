import {ListGroup} from "react-bootstrap"
import {ReactComponent as MoneySvg} from "../../assets/svg/money.svg"
import {ReactComponent as SmallLogoSvg} from "../../assets/svg/logo/small.svg"
import {ReactComponent as HashSvg} from "../../assets/svg/hash.svg"
import {ReactComponent as BankSvg} from "../../assets/svg/bank.svg"
import {ReactComponent as PagaAgentSvg} from "../../assets/svg/paga-agent.svg"
import {ReactComponent as MoreSvg} from "../../assets/svg/more.svg"
import PaymentItem from "./PaymentItem"
import '../../styles/page.css'
import {useMenuSelection} from "../../contexts/MenuSelectionContext"
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
    displayMobile: PropTypes.bool,
    itemPadding: PropTypes.string
}

const defaultProps = {
    displayMobile: false,
    itemPadding: '11px'
}

const PaymentMenu = ({displayMobile, itemPadding, logo}) => {
    const {primaryChannels, secondaryChannels} = useMenuSelection()
    const classes = classNames(
        !displayMobile && 'menu',
        !displayMobile && 'no-display-mobile'
    )
    const Logo = logo
    function getLogo(channel) {
        switch (channel) {
            case 'CARD':
                return <MoneySvg/>;
            case 'PAGA':
                return logo? <Logo/> : <SmallLogoSvg/>;
            case 'TRANSFER':
                return <BankSvg/>;
            case 'AGENT':
                return <PagaAgentSvg/>;
            case 'USSD':
                return <HashSvg/>;
            default:
                return <MoneySvg/>;
        }
    }

    return (
        <div className={classes}>
            <div className="payment-menu-header">Ways to pay</div>
            <ListGroup>
                {primaryChannels().map(channel => (
                    <PaymentItem
                        key={channel}
                        text={channel}
                        link={channel.toLowerCase()}
                        logo={getLogo(channel)}
                        formatText={channel !== 'USSD'}
                        verticalPadding={itemPadding}
                    />
                ))}
                <PaymentItem show={secondaryChannels().length > 0} text="More" link="other" logo={MoreSvg}/>
            </ListGroup>
        </div>
    )
}

PaymentMenu.propTypes = propTypes;
PaymentMenu.defaultProps = defaultProps;

export default PaymentMenu