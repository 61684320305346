import React from 'react';
import PropTypes from 'prop-types';
import Countdown from "react-countdown";

const propTypes = {
  seconds: PropTypes.number,
  onCompletion: PropTypes.func,
  type: PropTypes.string
}

const defaultProps = {
  seconds: 90,
  onCompletion: ()=>{},
  type: ''
}

const CountdownTimer = ({
  seconds,
  onCompletion,
  type,
}) => {
  const milliseconds = seconds * 1000
  const renderer = ({minutes, seconds, hours }) => {
    let secondsText
    let minutesText

    switch (type){
      case 'seconds':
        secondsText = seconds <= 1 ? 'second' : 'seconds'
        return (
            <span className="secondary-color">
            {seconds} {secondsText}
          </span>
        );
      case 'minutes':
        minutesText = minutes <= 1 ? '' : 'minutes'
        return (
            <span className="secondary-color">
            {
              minutes <= 1?
                  'a minute' : <>{minutes} {minutesText}</>
            }
          </span>
        );
      case 'hours':
        secondsText = seconds < 10 ? '0'+seconds : seconds
        minutesText = minutes < 10 ? '0'+minutes : minutes
        return (
            <span className="secondary-color">
            {hours}h:{minutesText}m:{secondsText}s
          </span>
        );
      default:
        secondsText = seconds < 10 ? '0'+seconds : seconds
        return (
            <span className="secondary-color">
            {minutes}:{secondsText}s
          </span>
        );
    }
  };

  const time = React.useMemo(() => {
    return Date.now() + milliseconds;
  }, [milliseconds]);

  return (
      <Countdown
          date={time}
          renderer={renderer}
          onComplete={onCompletion}
      />
  );
}

CountdownTimer.propTypes = propTypes;
CountdownTimer.defaultProps = defaultProps;

export default CountdownTimer;