import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Button} from "react-bootstrap";
import {SyncLoader} from "react-spinners";

const propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  loading: PropTypes.bool,
  loadingChildren: PropTypes.any,
  disabled: PropTypes.bool,
  padding: PropTypes.bool,
  transparent: PropTypes.bool
}

const defaultProps = {
  color: `#5c91ee`,
  textColor: `#ffffff`,
  loading: false,
  disabled: false,
  transparent: false
}

const ButtonWidget = ({
  className,
  color,
  textColor,
  transparent,
  loading,
  loadingChildren,
  disabled,
  children,
  ...props
}) => {

  const classes = classNames(
    loading && 'is-loading',
    'text-center',
    'btn-block',
    'fw-semibold',
    className
  );

  let btnColor

  if(transparent) {
    btnColor = "transparent"
    textColor = color

    if(disabled || loading){
      textColor = "#929293"
    }
  }else{
    if(disabled || loading){
      btnColor = "#eeeeee"
      textColor = "#929293"
    }else{
      btnColor = color
    }
  }

  return (
    <Button
      {...props}
      className={classes}
      style={{backgroundColor: btnColor, color: textColor}}
      disabled={loading || disabled}
    >
      <div className="d-flex justify-content-center align-items-center">
        {loading && loadingChildren? loadingChildren : children}
        {!loading ? null :
            <SyncLoader className="mx-2" size={5} color={color} speedMultiplier={0.8}/>
        }
      </div>
    </Button>
  );
}

ButtonWidget.propTypes = propTypes;
ButtonWidget.defaultProps = defaultProps;

export default ButtonWidget;