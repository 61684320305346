export function cardNumberValidation(cardNumber, cardType) {
    let cardLength
    switch (cardType){
        case 'VERVE':
            cardLength = 18
            break
        default:
            cardLength = 16
            break
    }

    return cardNumber.length < cardLength ? "Your card number is incomplete": ""
}

// export function findCardType(cardNumber) {
//     const regexPattern = {
//         MASTERCARD: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
//         VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
//         VERVE: /^((506(0|1))|(507(8|9))|(6500))[0-9]{12,15}$/
//     };
//
//     for (const card in regexPattern) {
//         if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])){
//             return card;
//         }
//     }
//
//     return "";
// }

export function findCardType(cardNumber, cardScheme = null) {
    const regexPattern = {
        MASTERCARD: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/,
        VISA: /^4[0-9]{3}/,
        VERVE: /^((506(0|1))|(507(8|9))|(6500))/
    };

    if (!cardScheme) {
        cardScheme = Object.keys(regexPattern);
    }

    for (const card of cardScheme) {
        if (cardNumber.match(regexPattern[card])) {
            return card;
        }
    }

    return "";
}

export const cardExpiryDateValidation = (value) => {
    if (value) {
        if (/^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim())) {
            let today = new Date();
            let CurrentDate =
                new Date(
                    today.getFullYear(),
                    (today.getMonth() + 1),
                    new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
            );

            let dateValueSplit

            if (value.includes('-')) {
                dateValueSplit = value.split("-");
            } else {
                dateValueSplit = value.split("/");
            }

            let date = new Date(`20${dateValueSplit[1]}`, dateValueSplit[0], 0);
            return CurrentDate < date
                ? ""
                : "Please enter a valid date";
        } else {
            return "Invalid date format";
        }
    }
};

export const minLength = (min) => (value) =>
    value && value.length < min ? `Must be ${min} digits` : "";
