import axios from 'axios';
import {getBaseUrl, getOtpRequestPayload, getRequestPayload} from "./BaseService";

axios.defaults.withCredentials = true

export async function chargeLinkedCard(paymentParams, fundingSourceId){
    const body = getRequestPayload(paymentParams, null, {
        'fundingSourceId': fundingSourceId
    })

    const response = await axios.post(getBaseUrl() + "/paga/charge-card/v1.0", body);
    return response.data
}

export async function chargeLinkedBankAccount(paymentParams, fundingSourceId, bankId){
    const body = getRequestPayload(paymentParams, null, {
        "bankId": bankId,
        "fundingSourceId": fundingSourceId,
    })

    const response = await axios.post(getBaseUrl() + "/paga/charge-bank/v1.0", body);
    return response.data
}

export async function chargePagaAccount(paymentParams, pagaAccountId){
    const body = getRequestPayload(paymentParams, null, {
        'fundingSourceId': pagaAccountId
    })

    const response = await axios.post(getBaseUrl() + "/paga/charge-paga/v1.0", body);
    return response.data
}


export async function verifyLinkedCardOTP(paymentParams, otp){
    const body = getOtpRequestPayload(paymentParams, otp);

    const response = await axios.post(getBaseUrl() + "/paga/authorize-card/v1.0", body);
    return response.data
}

export async function verifyLinkedBankOTP(paymentParams, otp){
    const body = getOtpRequestPayload(paymentParams, otp);

    const response = await axios.post(getBaseUrl() + "/paga/authorize-bank/v1.0", body);
    return response.data
}

export async function resendLinkedCardOTP(paymentParams){
    const body = {
        "processReferenceNumber": paymentParams.payment_reference,
        "merchantAccountId": paymentParams.public_key
    }

    const response = await axios.post(getBaseUrl() + "/paga/reset-card/v1.0", body);
    return response.data
}

export async function resendLinkedBankOTP(paymentParams){
    const body = {
        "processReferenceNumber": paymentParams.payment_reference,
        "merchantAccountId": paymentParams.public_key
    }

    const response = await axios.post(getBaseUrl() + "/paga/reset-bank/v1.0", body);
    return response.data
}

export function logOut(){
    const url = '/logout'

    return axios.get(getBaseUrl() + url);
}