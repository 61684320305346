import {createContext, useContext, useState} from "react"

export class TransferStatus {
    static UNINITIALIZED = "uninitialized";
    static INITIALIZED = "initialized";
    static FAILED = "failed";
    static SUCCESS = "success"

    constructor(type) {
        this.type = type;
    }
}

export const TransferStatusContext = createContext(null)

export const TransferStatusContextProvider = ({ children }) => {
    const [transferStatus, setTransferStatus] = useState({
        status: TransferStatus.UNINITIALIZED,
        message: ''
    });

    const [transferDetails, setTransferDetails] = useState({
        accountNumber: '',
        accountName: '',
        bankName: '',
        requestedAt: null
    })

    const loadTransferDetails = () => {
        setTransferStatus({
            status: TransferStatus.UNINITIALIZED,
            message: ''
        })
    }

    const showTransferDetails = () => {
        setTransferStatus({
            status: TransferStatus.INITIALIZED,
            message: ''
        })
    }

    const showFailed = (message) => {
        setTransferStatus({
            status: TransferStatus.FAILED,
            message: message
        })
    }

    const showSuccess = () => {
        setTransferStatus({
            status: TransferStatus.SUCCESS,
            message: ''
        })
    }

    const setErrorMessage = (message) => {
        setTransferStatus({
            status: transferStatus.status,
            message: message
        })
    }

    const value = {transferStatus, setErrorMessage, showFailed, showSuccess, showTransferDetails, loadTransferDetails,
        transferDetails, setTransferDetails}

    return(
        <TransferStatusContext.Provider value={value}> {children} </TransferStatusContext.Provider>
    );
}

export default TransferStatusContext;

export const useTransferStatus = ()=>{
    return useContext(TransferStatusContext);
}