import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as ErrorSvg} from "../../assets/svg/error.svg";

const propTypes = {
  error: PropTypes.string,
}

const defaultProps = {
  error: '',
}

const FormInputError = ({
  children,
}) => {

  return (
    <div className="d-flex align-items-center error">
      <ErrorSvg/>
      <div className="form-error">
        {children}
      </div>
    </div>
  );
}

FormInputError.propTypes = propTypes;
FormInputError.defaultProps = defaultProps;

export default FormInputError;