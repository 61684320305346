import ButtonWidget from "../../components/elements/ButtonWidget"
import OTPInputField from "../../components/elements/OTPInputField"
import {usePaymentStatus} from "../../contexts/PaymentStatusContext"
import {ErrorClass} from "../../constants/ErrorConstants";
import {useState} from "react";
import PropTypes from "prop-types";
import PageHeading from "../../components/elements/PageHeading";

const propTypes = {
    onBack: PropTypes.func,
    onChargeCard: PropTypes.func
}

const defaultProps = {
    onBack: ()=>{},
    onChargeCard: ()=>{}
}

const PinAuthentication = ({onChargeCard, onBack})=>{
    const {showFailedModal} = usePaymentStatus()
    const [pin, setPin] = useState('')
    const [loading, setLoading] = useState(false)
    const noOfInputs = 4;

    const handleVerifyPIN = ()=>{
        // Make a request to the card charge endpoint using the card details and pin
        setLoading(true)
        try {
            onChargeCard(pin)
        } catch (err) {
            showFailedModal(ErrorClass.FAILED_INITIALIZATION)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="box">
            <div>
                <PageHeading className="mb-2" title=" " description=""
                             hasBackButton={true} onBackButtonClicked={()=>onBack()}/>
                <h5 className="modal-heading-text">Enter PIN</h5>
                <h6 className="modal-text mt-3 mb-4 text-sm">
                    To complete your payment, securely enter your card PIN
                </h6>

                <OTPInputField noOfInputs={noOfInputs} isSecure={true} hasError={false}
                               value={pin} onChange={(value)=>setPin(value)}/>
            </div>

            <div>
                <ButtonWidget className="modal-btn mt-3" disabled={pin.length < noOfInputs} onClick={handleVerifyPIN}
                    loading={loading}>
                    Proceed
                </ButtonWidget>
            </div>
        </div>
    )
}

PinAuthentication.propTypes = propTypes
PinAuthentication.defaultProps = defaultProps

export default PinAuthentication
