import {getBaseUrl} from "../../services/BaseService";
import Loader from "../../components/elements/Loader";
import PageHeading from "../../components/elements/PageHeading";
import {usePagaStatus} from "../../contexts/PagaStatusContext";

const Authorization = ()=>{
    const {loginInfo, showPayWithPaga, showLoggedOut} =  usePagaStatus()
    const setAuthUrl = () => {
        return getBaseUrl() + "/paga/account/v1.0?account_identifier=" + loginInfo
    }

    const onIframeLoad = () => {
        const iframe = document.getElementById('auth-iframe')

        // Add event listener for messages received from the auth server iframe
        window.addEventListener('message', function (event) {
            // Check if the event originated from the iframe after authentication
            if (event.origin === new URL(iframe.src).origin) {
                switch (event.data.status){
                    case 'success':
                        const fundingSources = event.data.fundingSources
                        // console.log(fundingSources)
                        showPayWithPaga(fundingSources)
                        break
                    case 'failed':
                        showLoggedOut()
                        break
                    default:
                        break
                }

                // Remove the auth server iframe from the document
                if (iframe) {
                    try {
                        document.body.removeChild(iframe)
                    }catch (e){}
                }
            }
        })
    }

    return (
        <div className="box iframe-loading">
            <PageHeading className="mb-3 only-display-mobile" title=" " description=""
                changeButtonPadding={true}/>
            <iframe
                id="auth-iframe"
                className="iframe"
                src={setAuthUrl()}
                title="Auth-server"
                height={"100%"}
                onLoad={onIframeLoad}/>
            <div className="page-text">
                <Loader/>
            </div>
        </div>
    )
}

export default Authorization
