import {createContext, useContext, useState} from "react"
import {usePayment} from "./PaymentContext"

export const MenuSelectionContext = createContext(null)

export const MenuSelectionContextProvider = ({ children }) => {
    const menuLength = 5
    const {paymentParams, defaultPaymentParams} = usePayment()
    const availableChannels = removeDuplicates(defaultPaymentParams.funding_sources.split(','))
    const channels = removeDuplicates(paymentParams.funding_sources.split(','))
        .filter(channel => availableChannels.includes(channel));
    const defaultChannel = channels[0].toLowerCase();
    const [selected, setSelected] = useState(defaultChannel)
    const [previousSelected, setPreviousSelected] = useState(selected)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const secondaryChannels = () => {
        if (menuLength < channels.length) {
            return channels.slice(menuLength);
        } else {
            return [];
        }
    }

    function removeDuplicates(arr) {
        const uniqueValues = new Set(arr);
        return Array.from(uniqueValues);
    }

    const primaryChannels = () => {
        return channels.slice(0, menuLength);
    }

    const changeSelected = (newValue) => {
        setPreviousSelected(selected)
        setSelected(newValue)
    }

    const changeToPreviousSelected = ()=>{
        setSelected(previousSelected)
    }

    const value = {channels, primaryChannels, secondaryChannels, selected, setSelected, mobileMenuOpen,
        setMobileMenuOpen, changeSelected, changeToPreviousSelected}

    return(
        <MenuSelectionContext.Provider value={value}> {children} </MenuSelectionContext.Provider>
    )
}

export default MenuSelectionContext

export const useMenuSelection = ()=>{
    return useContext(MenuSelectionContext)
}
