import OtpInput from "react-otp-input";
import PropTypes from "prop-types";

const propTypes = {
    hasError: PropTypes.bool,
    noOfInputs: PropTypes.number,
    isSecure: PropTypes.bool,
    onChange: PropTypes.func,
    inputWidth: PropTypes.number,
    inputPlaceholder: PropTypes.string
}

const defaultProps = {
    hasError: false,
    noOfInputs: 6,
    isSecure: false,
    inputWidth: 50,
    inputPlaceholder: '-'
}

const OTPInputField = ({className, hasError, noOfInputs, isSecure, value, onChange, inputWidth, inputPlaceholder}) => {
    const fieldWidth = noOfInputs * (inputWidth + 5) //plus spacing

    return (
        <OtpInput
            className={className}
            value={value}
            onChange={onChange}
            numInputs={noOfInputs}
            placeholder={inputPlaceholder.repeat(noOfInputs)}
            containerStyle={
                {
                    width: fieldWidth + 'px',
                    margin: 'auto',
                    'justifyContent': 'space-between'
                }
            }
            inputStyle={{
                width: inputWidth + 'px',
                height: inputWidth + 'px',
                'caretColor': 'transparent',
                'borderRadius': '6px',
                'border': '1px solid #e0e0e0'
            }}
            errorStyle={{
                'border': '1.5px solid #dd0a08'
            }}
            isInputNum={true}
            isInputSecure={isSecure}
            shouldAutoFocus={true}
            hasErrored={hasError}
            renderInput={(props) => <input {...props} />}/>
    );
};

OTPInputField.propTypes = propTypes;
OTPInputField.defaultProps = defaultProps;

export default OTPInputField;