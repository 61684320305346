import axios from "axios";
import {getBaseUrl, getRequestPayload} from "./BaseService";

axios.defaults.withCredentials = true

export async function getMerchantAndFeeDetails(paymentParams) {
    const url = "/merchant/details/v1.1";

    const body = getRequestPayload(paymentParams)

    const response = await axios.post(getBaseUrl() + url, body);
    return response.data;
}
