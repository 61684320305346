import {ReactComponent as MasterCardSvg} from "../../assets/svg/mastercard.svg"
import {ReactComponent as VerveSvg} from "../../assets/svg/verve.svg"
import {ReactComponent as VisaSvg} from "../../assets/svg/visa.svg"
import PageHeading from "../../components/elements/PageHeading"
import ButtonWidget from "../../components/elements/ButtonWidget"
import {useCard} from "../../contexts/CardContext"
import CardPaymentForm from "../../components/elements/CardPaymentForm"
import {useEffect, useState} from "react"
import {chargeCard, getVgsProxyUrl} from "../../services/CardService"
import {PaymentStatus, usePaymentStatus} from "../../contexts/PaymentStatusContext"
import {useSecure3D} from "../../contexts/Secure3DContext"
import OTPAuthentication from "../auth/OTPAuthentication"
import PinAuthentication from "../auth/PinAuthentication"
import FormError from "../../components/elements/FormError"
import {usePayment} from "../../contexts/PaymentContext"
import SuccessModal from "../SuccessModal"
import {useOtp} from "../../contexts/OTPContext"
import {findCardType} from "../../validators/CardValidator"
import {useMerchant} from "../../contexts/MerchantContext"
import {ErrorClass} from "../../constants/ErrorConstants"

const CardPayment = () => {
    const {paymentParams} = usePayment()
    const {setOtpRequestedAt} = useOtp()
    const {paymentStatus, setPaymentStatus, showSuccessModal, showFailedModal,
        showPINAuth, showOTPAuth, showSecure3DAuth, closeModal} = usePaymentStatus()
    const {setSecure3DWidget} = useSecure3D()
    const [loading, setLoading] = useState(false)
    const [vgsUrl, setVgsUrl] = useState(null)
    const {card, cardType, emptyCardDetails, hasError, hasEmptyCardDetail, hasIncompleteCardDetail} = useCard()
    const {getFeeDetailsByChannel} = useMerchant()
    const cardFeeDetails = cardType? getFeeDetailsByChannel('CARD', cardType): getFeeDetailsByChannel('CARD')

    useEffect(() => {
        return () => {
            closeModal();
            emptyCardDetails();
        }
    }, [])

    const handleSubmit = async () => {
        //reset form error
        setPaymentStatus({
            status: paymentStatus.status,
            message: ''
        })

        //set otp timer reference to now
        setOtpRequestedAt(new Date())

        //check if card is included in card scheme
        if(!paymentParams.card_scheme.includes(findCardType(card.cardNumber))){
            showFailedModal("Unsupported card type")
            return
        }
        let proxyUrl = null

        try {
            const vgsRes = await getVgsProxyUrl(card)
            proxyUrl = vgsRes.fundingSource.pciProxyUrl
            if (proxyUrl) {
                setVgsUrl(proxyUrl)
            }
        }catch (err){
            showFailedModal(ErrorClass.FAILED_INITIALIZATION)
        }

        handleCardCharge(null, proxyUrl)
    }

    const handleCardCharge = async (pin = null, proxyUrl = null) => {
        setLoading(true)

        // Make a request to the card charge endpoint using the card details
        try {
            const res = await chargeCard(paymentParams, card, proxyUrl? proxyUrl: vgsUrl, pin)

            if ((res.status === "SUCCESS") || (res.status === "SUCCESSFUL")) {
                showSuccessModal()

                // Card Authorization
            } else if (res.status === "PENDING_AUTHORIZATION") {
                switch (res.responseData.authorizationType) {
                    case "otp":
                        showOTPAuth(res.statusMessage)
                        break
                    case "3ds":
                        setSecure3DWidget({
                            html: res.responseData.authorizationHtml,
                            url: res.responseData.authorizationUrl
                        })
                        showSecure3DAuth()
                        break
                    case "pin":
                        showPINAuth()
                        break
                    default:
                        break
                }
            } else if (res.status === "FAILED") {
                showFailedModal(res.statusMessage)
            } else{
                showFailedModal(ErrorClass.FAILED_INITIALIZATION)
            }
        } catch (err) {
            //console.log(err)
            showFailedModal(ErrorClass.FAILED_INITIALIZATION)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="box">
            { paymentStatus.status === PaymentStatus.OTP ?
                <OTPAuthentication
                    title="Card Authentication"
                    message={paymentStatus.message}
                    onBack={(message)=>{
                        showFailedModal(message)
                    }}
                    otpLength={6}
                    onSuccess={()=>showSuccessModal()}
                />
                : null }

            { paymentStatus.status === PaymentStatus.PIN ?
                <PinAuthentication
                    onChargeCard={(pin)=>handleCardCharge(pin)}
                    onBack={(message)=>{
                        showFailedModal(message)
                    }}/> : null }

            { paymentStatus.status === PaymentStatus.START?
                <>
                    <div>
                        <PageHeading title="Pay with card" description="Enter your card details"/>
                        <div className="line-divider"></div>

                        <div className="card-gateways" style={{width: paymentParams.card_scheme.split(',').length * 35}}>
                            {
                                paymentParams.card_scheme.includes('VISA') &&
                                <VisaSvg/>
                            }

                            {
                                paymentParams.card_scheme.includes('MASTERCARD') &&
                                <MasterCardSvg/>
                            }

                            {
                                paymentParams.card_scheme.includes('VERVE') &&
                                <VerveSvg/>
                            }
                        </div>

                        { paymentStatus.message ?
                            <FormError className="mb-2">{paymentStatus.message}</FormError>: null }

                        {/* Card payment form*/}
                        <CardPaymentForm/>

                    </div>

                    <div className="d-flex flex-column justify-content-between">
                        <ButtonWidget className="modal-btn" type="submit" onClick={handleSubmit} loading={loading}
                              disabled={hasError() || hasEmptyCardDetail() || hasIncompleteCardDetail()}>
                            {'Pay ₦' + cardFeeDetails.total}
                        </ButtonWidget>
                        <p className="page-text text-sm mt-1">
                            {'Includes fee ₦' + cardFeeDetails.amount}
                        </p>
                    </div>
                </>
                : null
            }

            <SuccessModal show={paymentStatus.status === PaymentStatus.SUCCESS} onHide={()=>{}}
                          paymentDetails={{
                              refNo: paymentParams.payment_reference,
                              transactionType: 'Card',
                              amount: paymentParams.amount,
                              fee: cardFeeDetails.amount,
                              tax: cardFeeDetails.taxes? cardFeeDetails.taxes.vat: null,
                              total: cardFeeDetails.total
                          }}/>
        </div>
    )
}


export default CardPayment